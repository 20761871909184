import React from 'react';
import './form-datepicker.style.scss';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';

const FormDatePicker = ({ handleDateOfBirth,currentFontColors, selected, onChange,label,value, changeDateOfBirth}) => {
    const startDatePickerLink = React.createRef();
    const [fontColor, setFontColor] = useState("#a3a3a3");
    const yearsToShow = 120;
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - yearsToShow);    
    useEffect(() => {
        if (currentFontColors && currentFontColors.form_color) {
            const color = JSON.parse(currentFontColors.form_color);
            setFontColor(`rgba(${color.r }, ${color.g }, ${color.b }, ${color.a })`);
        }
    }, [currentFontColors]);

    return (
        <div className = {`group`}>
            <MDBCol className={`${changeDateOfBirth ? "red-outline" : ""} background-date-wrapper`}>
                <DatePicker
                    className={`form-datepicker ${value ? "non-empty" : ""}`}
                    ref={startDatePickerLink}
                    disabledKeyboardNavigation 
                    scrollableYearDropdown
                    showYearDropdown
                    showMonthDropdown
                    selected={value} 
                    onChange={date => handleDateOfBirth(date)}
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    dropdownMode="select"
                    minDate={minDate}
                    maxDate={new Date()}
                />
                <MDBIcon icon="calendar-alt" className="datePickerIcon" onClick={()=>startDatePickerLink.current.setOpen(true)}/>  
            </MDBCol>
            {
            label?
                (
                    <label className = {`${ value ? 'shrink' : '' } ${changeDateOfBirth ? "red-label" : ""} form-datepicker-label`}>
                        {label}
                    </label>
                )
                : null
            }
        </div>
    )
}

const MapStateToProps = ({ colors: {currentFontColors}}) => ({
    currentFontColors
})
export default connect(MapStateToProps)(FormDatePicker);