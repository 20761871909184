import React, { useRef, useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { isValidLoaExpirationDate } from "../../../utils/fflUtils";

const Map = ({
  locations,
  view,
  setFFLScopeInfo,
  setSelectedFFL,
  clicList,
  fflScopeInfo,
  setClick,
}) => {

  let infoWindow = new window.google.maps.InfoWindow();
  const ref = useRef();
  let map;
  function showMarkers(ffls, clicList) {
    ffls.forEach((location, i) => {
    
      if(Date.parse(location?.expiration )> Date.parse(new Date())){
        const marker = new window.google.maps.Marker({
          position: {
            lat: Number(location?.premiseLat),
            lng: Number(location?.premiseLon),
          },
          // icon: mapIcon,
        });

        if (clicList) {
          let obj = {
            fflNumber: fflScopeInfo?.fflNumber,
            licenseName: fflScopeInfo?.licenseName,
            businessName: fflScopeInfo?.businessName,
            expiration: fflScopeInfo?.expiration,
            premiseStreet: fflScopeInfo?.premiseStreet,
            premiseZipCode: fflScopeInfo?.premiseZipCode,
            premiseCity: fflScopeInfo?.premiseCity,
            premiseLat: fflScopeInfo?.premiseLat,
            premiseLon: fflScopeInfo?.premiseLon,
            voiceTelephone: fflScopeInfo?.voiceTelephone,
            verified: fflScopeInfo?.verified,
            fflVerificationProof: fflScopeInfo?.fflVerificationProof,
            premiseState: fflScopeInfo?.premiseState,
          };
          if (isValidLoaExpirationDate(fflScopeInfo)) {
            obj.loaExpirationDate = fflScopeInfo?.loaExpirationDate;
          }
          setSelectedFFL(true);
          setFFLScopeInfo(obj);
  
          infoWindow.setContent(
            `<div className='flex flex-col content-start p-2'>` +
              `<h3 className='font-semibold text-lg mb-2'>` +
              `${fflScopeInfo.licenseName}` +
              `</h3>` +
              `<h6>${fflScopeInfo.premiseStreet},${fflScopeInfo?.premiseState} ${fflScopeInfo?.premiseZipCode}, US</h6>` +
              `<h6>Expiration Date: ${fflScopeInfo?.expiration}</h6>`
          );
          clicList = null;
          infoWindow.open({
            anchor: marker,
            map,
            shouldFocus: true,
          });
        }
  
        marker.addListener("click", () => {
          let obj = {
            fflNumber: location?.fflNumber,
            licenseName: location?.licenseName,
            businessName: location?.businessName,
            expiration: location?.expiration,
            premiseStreet: location?.premiseStreet,
            premiseZipCode: location?.premiseZipCode,
            premiseCity: location?.premiseCity,
            premiseLat: location?.premiseLat,
            premiseLon: location?.premiseLon,
            voiceTelephone: location?.voiceTelephone,
            verified: location?.verified,
            fflVerificationProof: location?.fflVerificationProof,
            premiseState: location?.premiseState,
          };
  
          setSelectedFFL(true);
          setFFLScopeInfo(obj);
          setClick(true);
          infoWindow.setContent(
            `<div className='flex flex-col content-start p-2'>` +
              `<h3 className='font-semibold text-lg mb-2'>` +
              `${location?.licenseName}` +
              `</h3>` +
              `<h6>${location?.premiseStreet},${location?.premiseState} ${location?.premiseZipCode}, US</h6>` +
              `<h6>Expiration Date: ${location?.expiration}</h6>`
          );
          infoWindow.open({
            anchor: marker,
            map,
            shouldFocus: true,
          });
          document.querySelector("#ghost").click();
        });
        marker.setMap(map);
      }
    
      
    });
  }
  useEffect(() => {
    map = new window.google.maps.Map(ref.current, {
      zoom: view.zoomLevel,
      center: view.center,
      mapTypeControl: false,
    });
    if (ref.current) {
      // setTimeout( () => {
      showMarkers(locations, clicList);
      // }, 5000)
    }
  }, [locations.length, view, clicList]);

  return (
    <>
      <div ref={ref} id="map" style={{ width: "100%", height: "500px" }} />
    </>
  );
};

export default Map;
