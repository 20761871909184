export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const createYearsArray = () => {
  const currentYear = new Date().getFullYear();
  const endYear = 2050;
  const years = [];

  for (let i = currentYear; i <= endYear; i++) {
    years.push(i);
  }

  return years;
}


export const YEARS = createYearsArray();