import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { sendForgotPassword } from '../../redux/user/user.action';
import './forgot-password.style.scss';
import FormInput from '../../components/form-input/form-input.component';
import FormButton from '../../components/form-button/form-button.component';
import { useAlert } from 'react-alert';
import VerifyCodeInput from '../../components/verify-code-input/verify-code-input.component';
import { Auth } from 'aws-amplify';
import { setVerifiedUser } from '../../redux/user/user.action';

const unconfirmedAccountError = "The User Profile is not verified yet, verify your account."

const ForgotPassword = ({ sendForgotPassword, currentFontColors, setVerifiedUser }) => {

    const [fontColors, setFontColors] = useState({
        header: "white",
        content: "#a3a3a3"
    });

    const [credential, setCredential] = useState({ email:'', pass:'', confirmcode:''});
    const [userVerified, setUserVerified] = useState(false);

    useEffect(() => {
        if (currentFontColors && currentFontColors.header1_color && currentFontColors.paragraph_color) {
            const hColor = JSON.parse(currentFontColors.header1_color);
            const pColor = JSON.parse(currentFontColors.paragraph_color);
            setFontColors({
                header: `rgba(${hColor.r }, ${hColor.g }, ${hColor.b }, ${hColor.a })`,
                content: `rgba(${pColor.r }, ${pColor.g }, ${pColor.b }, ${pColor.a })`
            })
        }
    }, [currentFontColors]);

    const handleConfirmSubmit = async event => {
        event.preventDefault();
        if(isLoading)
            return;
        setIsLoading(true);
        
        try {
            await Auth.confirmSignUp(credential.email, credential.confirmcode);
            await setVerifiedUser(credential.email);
            setIsLoading(false);
            setUserVerified(true);
            setIsAccountUnconfirmed(false);
            setShowAcoountConfirmForm(false);
        } catch (error) {
            alert.error(error.message);
            setIsLoading(false);
        }
    }

    const alert = useAlert();

    const [forgotPass, setForgotPass] = useState({email: ""});    
    const [isLoading, setIsLoading] = useState(false);   
    const [isAccountUnconfirmed, setIsAccountUnconfirmed] = useState(false);
    const [showAcoountConfirmForm, setShowAcoountConfirmForm] = useState(false);

    const handleSendCodeClick = async event => {
        if(isLoading)
            return;
        setIsLoading(true);

        event.preventDefault();   
        
        const result = await sendForgotPassword(forgotPass.email);
        if (result === "success") {
            alert.success("Request success! Check your email.");
        } else if ( result.message ) {
            alert.error(result.message);
            if (result.message === unconfirmedAccountError) {
                setCredential({email: forgotPass.email, pass: '', code: ''})
                setIsAccountUnconfirmed(true);
            }
        } else {
            alert.error("Unregistered user!");
        }

        setIsLoading(false);
    };

    const showAcoountConfirmFormOnClick = async () => {
        debugger
        try {
            await Auth.resendSignUp( credential.email);   
            setCredential({ email: forgotPass.email, pass: '', code: '' });
            setShowAcoountConfirmForm(true);
        } catch(error) {
            alert.error("Error");
        }
    }

    const renderRequestCodeForm = () => (     
        <div className="reqCodeForm">
            <form onSubmit = { handleSendCodeClick }>
                <h2 className='req-title' style={{color: fontColors.header}}>Forgot Password</h2>
                <FormInput 
                    type="email" 
                    name = "email" 
                    label = "E-mail Address" 
                    value = {forgotPass.email} 
                    handleChange = {(event) => setForgotPass({ ...forgotPass, email: event.target.value.toLowerCase()})} 
                    required/>
                <div className="emailExistLabel">
                    <p className="font-weight-bolder text-center" style={{color: fontColors.content}}>If an account with this email exists, a password reset email will be sent shortly. </p>    
                </div>                
                <FormButton 
                    type="submit" 
                    isLoading={isLoading}
                    isDisabled={isAccountUnconfirmed}>
                    SEND
                </FormButton>   
            </form>
            { isAccountUnconfirmed ?
                <button className="resend-confirmation-code-button" onClick={showAcoountConfirmFormOnClick}>
                    <span className="underline">Click here to confirm.</span>
                </button>
            : null }
        </div>         
    );

    const renderConfirmAccount = () => (
        <div className="reqCodeForm verify-form">
            <VerifyCodeInput
                handleConfirmSubmit={handleConfirmSubmit}
                email_or_phone={"Email"}
                phone={''} 
                credential={credential}
                setCredential={setCredential}
                isLoading={isLoading}
            />
        </div>
    )


    return(
        <div className="forgot-password-page">
            {
                showAcoountConfirmForm ? renderConfirmAccount()
                : renderRequestCodeForm()
            }
        </div>
    )
};

const MapStateToProps = ({ colors: { currentFontColors }}) => ({
    currentFontColors
})

const MapDispatchToProps = dispatch => ({
    sendForgotPassword: sendForgotPassword(dispatch),
    setVerifiedUser: setVerifiedUser(dispatch),
});

export default connect( MapStateToProps, MapDispatchToProps)(ForgotPassword);
