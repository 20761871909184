import { connect } from 'react-redux'
import React from 'react'

import './loader.styles.scss'

const Loader = () => {
  return (
    <div className="container-spinner">
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </div>
  )
}

export default connect()(Loader)
