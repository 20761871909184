const PurchasedItemsActionTypes = {
    SET_PURCHASED_PRODUCT_ITEMS: "SET_PURCHASED_PRODUCT_ITEMS",
    SET_PURCHASED_WEBINAR_ITEMS: "SET_PURCHASED_WEBINAR_ITEMS",
    SET_FILTER_STRING: "SET_FILTER_STRING",
    SET_LIMIT_PER_PAGE: "SET_LIMIT_PER_PAGE",
    SET_PRODUCT_PAGE_NUM: "SET_PRODUCT_PAGE_NUM",
    SET_WONLIST_PAGE_NUM: "SET_WONLIST_PAGE_NUM",
    SET_IS_TABLE_LOAD: "SET_IS_TABLE_LOAD"
};

export default PurchasedItemsActionTypes;