import { ProductsActionTypes } from './products.types';
import { RestAPI } from '../api-config';
import axios from 'axios';
import { serialize } from '../../utils/appUtils';
import { getImageFromS3 } from '../../utils/services/s3';
import { parseProductsImages } from '../../utils/parseProductimages';

export const getProducts = dispatch => async (limit = null, offset=null, type=null,selectedProdType=null,selectedCategory=null) => {
    const sendInfo = {
        limit: limit,
        offset: offset,
        product_type: type,
        prodType: selectedProdType,
        category: selectedCategory
    }
    console.log("sendInfo getProducts");
    console.log(sendInfo);
    try {
        if(sendInfo.product_type=='webinar'){
            const result = await axios.get(RestAPI.ORIGINAL_ENDPOINT + `products/getall?${serialize(sendInfo)}`);
            const data = parseProductsImages(result.data.data);
            dispatch(setProducts({
                count: result.data.count,
                data
            }));
            console.log("result.data.data", data);
        } else if (sendInfo.product_type==='physical' && sendInfo.prodType!=undefined && sendInfo.prodType!='matas'){
            const result = {count:0,data:[]};
            const lipseyResult = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `products/LipseysProducts?${serialize(sendInfo)}`);
            console.log("lipseyResult", lipseyResult.data.data);
            result.data = parseProductsImages(lipseyResult.data.data);
            result.count=lipseyResult.data.length;
            dispatch(setProducts(result));    
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const addMoreProducts = dispatch => async (limit = null, offset=null, type=null) => {
    const sendInfo = {
        limit: limit,
        offset: offset,
        product_type: type
    }
    try {
        const result = await axios.get(RestAPI.ORIGINAL_ENDPOINT + `products/getall?${serialize(sendInfo)}`);
        const data = parseProductsImages(result?.data?.data);
        dispatch(addProducts({
            count: result.data.count,
            data
        }));
    } catch (error) {
        console.log(error.message);
    }
}

export const setLimit = limit => ({
    type: ProductsActionTypes.SET_LIMIT,
    payload: limit
})

export const setOffset = offset => ({
    type: ProductsActionTypes.SET_OFFSET,
    payload: offset
})

const setProducts = items => ({
    type: ProductsActionTypes.SET_PRODUCTS,
    payload: items
})

const addProducts = items => ({
    type: ProductsActionTypes.ADD_MORE_PRODUCTS,
    payload: items
})
