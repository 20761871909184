import { getImageFromS3 } from "./services/s3";

export const parseProductsImages = (items) => {
  let result = []
  if(items.length>0){
    result = items.map(item => {
      if(item?.product_type == "lipseyProduct"){
        item.imageName = "https://www.lipseyscloud.com/images/" +
              item?.imageName +
              "?height=450&width=440&scale=canvas";
      } else{
        if(item?.main_image){
          if(item?.main_image?.image_url){
            item.main_image.image_url = getImageFromS3(item?.main_image?.image_url);
          } else{
            item.main_image.image_url = "";
          }
        }
      }
      return item;
    })
  }
  return result;
}