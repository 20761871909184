import FFLDBActionTypes from './ffl-db.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI } from '../api-config';
import { serialize } from '../../utils/appUtils';

export const getFFLLicences = dispatch => async (zipcode,radius) => {
    let searchInfo={
        zipcode:zipcode,
        radius:(radius==null || radius == '')?10:(radius>25?25:radius)
    }
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `consumer/ffl-database?${serialize(searchInfo)}`, {
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);
    }
}

export const getFFLScopeInfo = dispatch => async ( user_id) => {
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + "consumer/ffl-info/"+ user_id, {
            headers: (await getMyTokenFunc())
        });
        return result.data.data;
    } catch (error) {
        console.log(error?.response?.data?.message);
    }    
}

export const saveFFLInformation = dispatch => async (obj,id) => {
    let saveFFLData={
        fflScopeData:obj,
        userID:id
    }    
    try {
        const result = await axios.post(RestAPI.ORIGINAL_ENDPOINT + "consumer/ffl-database/saveFFL", saveFFLData,{
            headers: (await getMyTokenFunc())
        });
        return result.data;
        
    } catch (error) {
        console.log(error.message);
    }
}

export const getFFLLicencesFiltered = dispatch => async ({
    zipcode,
    radius,
    number='',
    name='',
    type='zipcode'
}) => {
    
    const setRoute = (type) => {
        let route = RestAPI.ORIGINAL_ENDPOINT + `consumer/ffl-database/filter?type=${type}`
        switch (type) {
            case 'zipcode':
                route = route + `&zipcode=${zipcode}&radius=${radius}`
                break;
            case 'name':
                route = route + `&name=${name}`
                break;
            case 'number':
                route = route + `&number=${number}`
                break;
            default:
                route = route + `&zipcode=${zipcode}&radius=${radius}`
                break;
        }
        return route;
    }
    try {
        const result = await axios.get(setRoute(type), {
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);
    }
}

export const userHasFflSelected = dispatch => async (id) => {
        
    try {
        const result = await axios.get(RestAPI.ORIGINAL_ENDPOINT + `consumer/user-has-ffl-selected?id=${id}`, {
            headers: (await getMyTokenFunc())
        });
        return result.data;
        
    } catch (error) {
        console.log(error.message);
    }
}

export const getNewerFfl = dispatch => async (id) => {
        
    try {
        const result = await axios.get(RestAPI.ORIGINAL_ENDPOINT + `consumer/user-get-newer-ffl?id=${id}`, {
            headers: (await getMyTokenFunc())
        });
        return result.data;
        
    } catch (error) {
        console.log(error.message);
    }
}

const setProductItems = items => ({
    type: FFLDBActionTypes.SET_PRODUCT_LIST_ITEMS,
    payload: items
})

const setCurrentFFLLicences = list => ({
    type: FFLDBActionTypes.SET_CURRENT_LICENCES,
    payload: list
});
