import {createStore, applyMiddleware, compose} from 'redux';
import {logger} from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './root-reducer';

//const middleware = [logger];
const middleware = [];

const store = createStore(rootReducer, compose(applyMiddleware(...middleware, thunk), window.__REDUX_DEVTOOLS_EXTENSION__
? window.__REDUX_DEVTOOLS_EXTENSION__()
: f => f));

export default store;