import React from "react";
import "./product-gallery-item.style.scss";
import { useHistory } from "react-router-dom";
import { MDBCol, MDBIcon } from "mdbreact";
import { connect } from "react-redux";
import { useState, useEffect } from "react";

const ProductGalleryItem = (props) => {
  const { item, currentFontColors } = props;
  const historyUrl = useHistory();

  const [colors, setColors] = useState({ header1: "white", header2: "white" });

  useEffect(() => {
    if (
      currentFontColors &&
      currentFontColors.header1_color &&
      currentFontColors.header2_color
    ) {
      const header1color = JSON.parse(currentFontColors.header1_color);
      const header2color = JSON.parse(currentFontColors.header2_color);
      setColors({
        header1: `rgba(${header1color.r}, ${header1color.g}, ${header1color.b}, ${header1color.a})`,
        header2: `rgba(${header2color.r}, ${header2color.g}, ${header2color.b}, ${header2color.a})`,
      });
    }
  }, [currentFontColors]);

  const clickFunc = (data) => {
    let currentUrl = historyUrl.location?.pathname?.split("/")[2];
    localStorage.setItem("scroll", props.setScroll ? props.setScroll() : 0)
    if (data.item?.product_type == "lipseyProduct") {
      historyUrl.push(`/products/LIP/${item?.upc_code}`,{
        category: currentUrl,
        from: data.item?.product_type,
        hasScroll: props.setScroll ? props.setScroll() : 0
      });
    } else {
      if(item && item?.id && item?.product_type){
        historyUrl.push(`/products/${item?.product_type}/${item?.id}`,{
          category: currentUrl,
          from: data.item?.product_type,
          hasScroll: props.setScroll ? props.setScroll() : 0
        });
      }

    }
  };
  return (
    <MDBCol size="12" sm="6" md="6" lg="6" xl="3" className="list-item">
      <div className="img-wrapper" onClick={() => clickFunc( {item} )}>
        {item?.main_image?.image_url ? (
          <img src={item?.main_image?.image_url} alt={item?.name} />
        ) : item?.imageName ? (
          <img src={item?.imageName} alt={item?.name} />
        ) :
        (
          <div className="non-img">
            <MDBIcon far icon="file-image" />
          </div>
        )}
        <label>{item?.product_type === "webinar" ? "WEBINAR" : "PRODUCT"}</label>
      </div>
      <p className="text-center mt-2" style={{ color: colors.header1 }}>
        {item?.product_name || "No Name"}
      </p>
      <p className="text-center" style={{ color: colors.header2 }}>
        <span style={{ color: colors.header2 }}>
          ${item?.product_price.toFixed(2)}
        </span>{" "}
        each |
        <span style={{ color: colors.header2 }}>
          {item?.product_count < 10 && item?.product_type == "lipseyProduct"
            ? "< 10"
            : item?.product_count}
        </span>{" "}
        {item?.product_type === "webinar" ? "seats remaining" : "left in stock"}
      </p>
    </MDBCol>
  );
};

const MapStateToProps = ({ colors: { currentFontColors } }) => ({
  currentFontColors,
});
export default connect(MapStateToProps)(ProductGalleryItem);
