import React, { useMemo, useState } from 'react'
import { MDBCol, MDBModal, MDBModalBody, MDBModalFooter, MDBRow } from 'mdbreact';
import { useAlert } from 'react-alert';

import FormButton from '../form-button/form-button.component';
import FormSelectComponent from '../form-select/form-select.component';
import CreditCardNumberInput from '../payment-form/ccNum-input/ccNum-input.component';
import { MONTHS, YEARS } from './constants';

import './modalCC.style.scss'

const { REACT_APP_ACCEPT_CLIENT_KEY, REACT_APP_ACCEPT_API_LOGIN_ID } = process.env;

const ModalCC = ({
  updateCurrentUser, profileInfo, modalShow, setModalShow
}) => {

  // Hooks
  const alert = useAlert();

  const paymentConfig = {
    clientKey: REACT_APP_ACCEPT_CLIENT_KEY,
    apiLoginId: REACT_APP_ACCEPT_API_LOGIN_ID,
  };

  // States
  const [fields, setFields] = useState({
    card: "",
    month: "month",
    year: "year",
    cvc: "",
  });

  const [optionShow1, setOptionShow1] = useState(false);
  const [placeholder1, setPlaceholder1] = useState("Month");

  const [optionShow2, setOptionShow2] = useState(false);
  const [placeholder2, setPlaceholder2] = useState("Year");


  // Functions

  const handlerCard = ({ target }) => {
    const value = target.value ? target.value.replace(/[\sa-zA-z]/g, "").trim() : "";
    const card = value
      ? value
        .match(/.{1,4}/g)
        .join(" ")
        .substr(0, 19)
      : "";
    setFields({ ...fields, card });
  };

  const cvcNumberFormat = (e) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setFields({ ...fields, cvc: e.target.value });
    }
  };

  const validateData = async () => {
    const authData = {};
    authData.clientKey = paymentConfig.clientKey;
    authData.apiLoginID = paymentConfig.apiLoginId;
    const cardData = {};
    cardData.cardNumber = fields.card.replace(/\s/g, "");
    cardData.month = fields.month;
    cardData.year = fields.year;
    cardData.cardCode = fields.cvc;
    const secureData = {};
    secureData.authData = authData;
    secureData.cardData = cardData;
    return new Promise((resolve, reject) => {
      
      let lastResponse = null;
  
      window.Accept.dispatchData(secureData, (res) => {
        // just in case it still gets called twice, ignore the second call
        if (res == lastResponse) {
          console.log("skipping duplicated responseHandler() call because it's the same response as the previous response");
          return;
        }
        lastResponse = res;
        console.log("responseHandler function called");
  
        if (res.messages.resultCode === "Error") {
          alert.error("There is an error in your payment information. Please try again.");
          return reject(res);
        }
        resolve(res);
      });
      
    });
  }

  const sendPaymentDataToAnet = async () => {
    let res = await validateData();
    await updateCurrentUser({
      profileInfo,
      opaqueData: res?.opaqueData,
    })
    
  };


  const submitDisabled = useMemo(
    () => {
      return (
        fields.card.length < 18 ||
        fields.month === "month" ||
        ((Number(fields.year) === new Date().getFullYear() - 2000) && (Number(fields.month) < new Date().getMonth() + 1)) ||
        !fields.month ||
        fields.year === "year" ||
        !fields.year ||
        fields.cvc.length < 3
      )
    }
    ,
    [fields]
  );

  return (
    <MDBModal isOpen={modalShow} toggle={() => setModalShow(!modalShow)} centered>
      <MDBModalBody>
        <p className="label">Card Number</p>
        <CreditCardNumberInput
          value={fields.card}
          onChange={handlerCard}
          forCC={true}
          placeHolder="Card Number"
          autoComplete="cc-number"
        />
        <MDBRow className="mb-4 mt-4 text-white">
          <MDBCol size="6">
            <p className="label">Expiration</p>
            <FormSelectComponent
              forPayment={true}
              textWhite={true}
              options={MONTHS}
              showSelectBox={() => setOptionShow1(!optionShow1)}
              selectOption={(event) => {
                setOptionShow1(false);
                setPlaceholder1(event.target.id);
                setFields({
                  ...fields,
                  month:
                    MONTHS.indexOf(event.target.id) + 1 < 10
                      ? "0" +
                      (
                        MONTHS.indexOf(event.target.id) + 1
                      ).toString()
                      : (
                        MONTHS.indexOf(event.target.id) + 1
                      ).toString(),
                });
              }}
              optionShow={optionShow1}
              placeholder={placeholder1}
            />
          </MDBCol>
          <MDBCol size="6" className='text-white'>
            <p className="label">&nbsp;</p>
            <FormSelectComponent
              forPayment={true}
              textWhite={true}
              options={YEARS}
              showSelectBox={() => setOptionShow2(!optionShow2)}
              selectOption={(event) => {
                setOptionShow2(false);
                setPlaceholder2(event.target.id);
                setFields({
                  ...fields,
                  year: event.target.id.slice(2),
                });
              }}
              optionShow={optionShow2}
              placeholder={placeholder2}
            />
          </MDBCol>
          <MDBCol size="12">
            <p className="label mt-2">CVC</p>

            <input
              className="cvc-input text-white"
              type="text"
              placeholder="CVC"
              value={fields.cvc}
              onChange={(event) => cvcNumberFormat(event)}
              maxLength="4"
              autoComplete="cc-csc"
            />
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
      <MDBModalFooter>
        <div className="btns">
          <FormButton type='button' onClickFunc={sendPaymentDataToAnet} isDisabled={submitDisabled}>
            SAVE
          </FormButton>
          <FormButton type='button' onClickFunc={() => setModalShow(!modalShow)}>
            CANCEL
          </FormButton>
        </div>
      </MDBModalFooter>
    </MDBModal>
  )
}

export default ModalCC