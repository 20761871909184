import React, {useEffect, useRef, useState} from 'react';
import './physical-product-page.style.scss';
import {MDBContainer, MDBRow, MDBCol, MDBIcon} from 'mdbreact';
import {connect} from 'react-redux';
import FormSelect from '../../components/form-select/form-select.component';
// for waiting load
import {setLoadAlerts} from '../../redux/alerts/alerts.action';
import {getWebinarPopUp} from '../../redux/others/others.action';
import ProductGalleryItem from '../../components/product-gallery-item/product-gallery-item.component';
import {getPhysicalProductCategories,getPhysicalProductManufacturers,getPhysicalProducts, addMoreProducts, setOffset} from '../../redux/physical-products/physical-products.action';
import {withRouter, useHistory} from 'react-router';
import InfiniteScroll from "react-infinite-scroll-component";
import {Helmet} from 'react-helmet';
import {useAlert, positions} from 'react-alert';

const PhysicalProductPage = withRouter(({
                                    location,getPhysicalProductCategories,getPhysicalProductManufacturers,isLoadingAlerts,getPhysicalProducts,addMoreProducts,getWebinarPopUp,items,limit,offset,setOffset,setLoadAlerts,
                                    match, hasScroll
                                }) => {
    const prodType = match.params.prodType;
    const orderOptions = ['Price: Low to High']
    const [selectedOrderOption, setSelectedOrderOption] = useState();
    const [manufacturers, setManufacturers] = useState([]);
    const [isIconSpin, setIsIconSpin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedManufacturer, setSelectedManufacturer] = useState();
    const [isOrderOptionsDropdownActive, setIsOrderOptionsDropdownActive ] = useState(false);
    const [isCategoriesDropdownActive, setIsCategoriesDropdownActive] = useState(false);
    const [isManufacturersDropdownActive, setIsManufacturersDropdownActive] = useState(false);
    const [webinarPopupInfo, setWebinarPopupInfo] = useState({
        content: ""
    });
    const [movedAfterCotinueShipping, setMovedAfterCotinueShipping] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);

    const mainElement = useRef(null);

    const historyUrl = useHistory();
    

    console.log("prodType", prodType);

    useEffect(() => {
        async function loadCategories() {
            const result = await getPhysicalProductCategories(prodType);
            setCategories(result)
        }
        loadCategories();
        setSelectedOrderOption(orderOptions[0])
    }, []);
    
    useEffect(()=>{
        if(categories?.length>0){
            setSelectedCategory(categories[0].name)
            async function loadManufacturers(){
                const manufacturersList= await getPhysicalProductManufacturers(prodType,categories[0].name)
                setManufacturers(manufacturersList)
            }
            loadManufacturers();
        }
    },[categories])
    
    useEffect(()=>{
        async function loadManufacturers(){
            const manufacturersList= await getPhysicalProductManufacturers(prodType,selectedCategory)
            setManufacturers(manufacturersList)
        }        
        loadManufacturers();  
    },[selectedCategory])

    useEffect(()=>{
        if(manufacturers!= undefined && manufacturers.length>0) setSelectedManufacturer(manufacturers[0])
    },[manufacturers])

    useEffect(() => {
        async function loadData() {
            setOffset(0);
            setLoadAlerts(true);
            setIsLoading(true);
            setIsIconSpin(true);
            await getPhysicalProducts(limit, 0, prodType,selectedCategory,selectedManufacturer);
            setLoadAlerts(false);
            setIsLoading(false);
            setIsIconSpin(false);
        }
        if(selectedCategory!=undefined && selectedManufacturer!=undefined){
            loadData();
        }

        async function loadWebinarPopup() {
            setLoadAlerts(true);
            const result = await getWebinarPopUp();
            setLoadAlerts(false);
            if (result) {
                setWebinarPopupInfo({
                    content: result.webinarPopUpInfo
                })
            }
        }

        loadWebinarPopup();
    }, [selectedManufacturer]);

    const loadMoreData = async () => {
        if (items.count < (offset + limit))
            {console.log("scrollPosition finish" )
            return;}
        setIsIconSpin(true);
        if (location?.state?.prodType) {
            await addMoreProducts(limit, offset + limit, location.state.prodType);
        } else {
            if (JSON.parse(localStorage.getItem('prodType')))
            await addMoreProducts(limit, offset + limit, JSON.parse(localStorage.getItem('prodType')).prodType);
        }
        setOffset(offset + limit);
        setIsIconSpin(false);

        console.log("scrollPosition", movedAfterCotinueShipping);
        let aboveInitial = parseInt(historyUrl?.location?.state?.hasScroll)
        if(aboveInitial > 250 && movedAfterCotinueShipping !== 1){
            window.scrollTo({
                top: aboveInitial,
                behavior: 'smooth'
            })
            if(window.scrollY === aboveInitial){
                setMovedAfterCotinueShipping(1)
                console.log("scrollPosition", movedAfterCotinueShipping);
            }
                
        }
    }

    useEffect(() => {
        if(historyUrl && historyUrl?.location?.state){
            if(historyUrl?.location?.state?.hasScroll){
                setTimeout(() => {
                    console.log("scrollPosition", parseInt(historyUrl.location.state.hasScroll));
                    let aboveInitial = parseInt(historyUrl.location.state.hasScroll)
                    if(aboveInitial > 270){
                        window.scrollTo({
                            top: aboveInitial,
                            behavior: 'smooth'
                        })
                    }
                }, 1300);
            }
        }
        }, [historyUrl])

    useEffect(() => {
        const handleScroll = () => {
          setScrollPosition(window.pageYOffset);
        };
    
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    return (
        <div className="physical-product-page" ref={mainElement}>
            <Helmet>
                {/* <!-- HTML Meta Tags --> */}
                <title>Products</title>
                <link rel="canonical" href={window.location.href}/>
                <meta
                    name="description"
                    content="Products of Mata's Tactical Supply"
                />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content="Products"/>
                <meta
                    itemprop="description"
                    content="Products of Mata's Tactical Supply"
                />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Products"/>
                <meta
                    property="og:description"
                    content="Products of Mata's Tactical Supply"
                />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:title" content="Products"/>
                <meta
                    name="twitter:description"
                    content="Products of Mata's Tactical Supply"
                />
            </Helmet>
            {
                isLoading &&
                <div className="load-icon-wrapper">
                    <MDBIcon className={`load-more load-icon ${isIconSpin && 'spin'} `} icon="sync-alt"/>
                </div>
            }
            {
                !isLoading &&
                <div>
                {
                items?.count === 0 ?
                <div>
                    <MDBContainer fluid className="dropdown-wrapper">
                        <MDBRow className="mt-4"> 
                            <MDBCol sm="12" md="12" lg="4" xl="4" className="mb-2 pl-2">
                                    <MDBRow className="d-flex align-items-center">
                                        <MDBCol className="dropdown-title">
                                            Order by:
                                        </MDBCol>
                                        <MDBCol className="">
                                            <FormSelect
                                                options={
                                                    orderOptions
                                                }
                                                showSelectBox={()=>setIsOrderOptionsDropdownActive(!isOrderOptionsDropdownActive)}
                                                selectOption={(event)=>{
                                                    setIsOrderOptionsDropdownActive(false);
                                                    // setSelectedOrderOption(event.target.id);
                                                }}
                                                optionShow={isOrderOptionsDropdownActive}
                                                placeholder={selectedOrderOption}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol sm="12" md="12" lg="4" xl="4" className="mb-2 pl-2">
                                    <MDBRow className="d-flex align-items-center">
                                        <MDBCol className="dropdown-title">
                                            Select a category:
                                        </MDBCol>
                                        <MDBCol className="">
                                            <FormSelect
                                                options={
                                                    categories.map(function(object) {return object['name']})
                                                }
                                                showSelectBox={()=>setIsCategoriesDropdownActive(!isCategoriesDropdownActive)}
                                                selectOption={(event)=>{
                                                    setIsCategoriesDropdownActive(false);
                                                    setSelectedCategory(event.target.id);
                                                }}
                                                optionShow={isCategoriesDropdownActive}
                                                placeholder={selectedCategory}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol sm="12" md="12" lg="4" xl="4" className="mb-2 pl-2">
                                    <MDBRow className="d-flex align-items-center">
                                        <MDBCol className="dropdown-title">
                                            Manufacturer:
                                        </MDBCol>
                                        <MDBCol className="">
                                            {
                                            <FormSelect
                                                options={
                                                    manufacturers
                                                }
                                                showSelectBox={()=>setIsManufacturersDropdownActive(!isManufacturersDropdownActive)}
                                                selectOption={(event)=>{
                                                    setIsManufacturersDropdownActive(false);
                                                    setSelectedManufacturer(event.target.id);
                                                }}
                                                optionShow={isManufacturersDropdownActive}
                                                placeholder={selectedManufacturer}
                                            />
                                            }                                      
                                        </MDBCol>   
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                    </MDBContainer>
                    <p className="noProducts">There are no products matching the current selection.</p>
                </div>
                    :
                    items?.data && !isIconSpin && items.data.length > 0 &&
                    <div>
                        <MDBContainer fluid className="dropdown-wrapper">
                            <MDBRow className="mt-4">
                                <MDBCol sm="12" md="12" lg="4" xl="4" className="mb-2 pl-2">
                                    <MDBRow className="d-flex align-items-center">
                                        <MDBCol className="dropdown-title">
                                            Order by:
                                        </MDBCol>
                                        <MDBCol className="">
                                            <FormSelect
                                                options={
                                                    orderOptions
                                                }
                                                showSelectBox={()=>setIsOrderOptionsDropdownActive(!isOrderOptionsDropdownActive)}
                                                selectOption={(event)=>{
                                                    setIsOrderOptionsDropdownActive(false);
                                                    // setSelectedOrderOption(event.target.id);
                                                }}
                                                optionShow={isOrderOptionsDropdownActive}
                                                placeholder={selectedOrderOption}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol sm="12" md="12" lg="4" xl="4" className="mb-2 pl-2">
                                    <MDBRow className="d-flex align-items-center">
                                        <MDBCol className="dropdown-title">
                                            Select a category:
                                        </MDBCol>
                                        <MDBCol className="">
                                            <FormSelect
                                                options={
                                                    categories.map(function(object) {return object['name']})
                                                }
                                                showSelectBox={()=>setIsCategoriesDropdownActive(!isCategoriesDropdownActive)}
                                                selectOption={(event)=>{
                                                    setIsCategoriesDropdownActive(false);
                                                    setSelectedCategory(event.target.id);
                                                }}
                                                optionShow={isCategoriesDropdownActive}
                                                placeholder={selectedCategory}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol sm="12" md="12" lg="4" xl="4" className="mb-2 pl-2">
                                    <MDBRow className="d-flex align-items-center">
                                        <MDBCol className="dropdown-title">
                                            Manufacturer:
                                        </MDBCol>
                                        <MDBCol className="">
                                            {
                                            <FormSelect
                                                options={
                                                    manufacturers
                                                }
                                                showSelectBox={()=>setIsManufacturersDropdownActive(!isManufacturersDropdownActive)}
                                                selectOption={(event)=>{
                                                    setIsManufacturersDropdownActive(false);
                                                    setSelectedManufacturer(event.target.id);
                                                }}
                                                optionShow={isManufacturersDropdownActive}
                                                placeholder={selectedManufacturer}
                                            />
                                            }                                      
                                        </MDBCol>   
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>                      
                        {
                            !items?.data[0]?.product_type?.includes("lipsey") ?
                            (
                            <InfiniteScroll
                            ref={mainElement}
                            className="own-infinite mt-3"
                            dataLength={items.data.length}
                            scrollThreshold={Math.min(0.8 * window.devicePixelRatio / 1.875, 0.8)} // move threshhold based on scale but not exceeding 0.8
                            next={loadMoreData}
                            hasMore={items.count > (offset + limit)}
                            loader={isIconSpin && <div className="load-icon-wrapper">
                                <MDBIcon className={`load-more load-icon ${isIconSpin && 'spin'} `} icon="sync-alt"/>
                            </div>}
                        >
                            <MDBRow className="row-prduct">
                                {
                                    !isLoadingAlerts && items && items.data && items.data.map((item, i) => (
                                        <ProductGalleryItem key={item.id} i={i} item={item} setScroll={() => scrollPosition}/>
                                    ))
                                }
                            </MDBRow>
                        </InfiniteScroll>
                            )
                            :
                            (
                                <MDBRow className="row-prduct">
                                {
                                    !isLoadingAlerts && items && items.data && items.data.map((item, i) => (
                                        <ProductGalleryItem key={item.id} i={i} item={item} setScroll={() => scrollPosition}/>
                                    ))
                                }
                            </MDBRow>
                            )
                        }
                    </div>
                }
                </div>
            }
        </div>
    )
});

const MapStateToProps = ({products: {items, limit, offset}, alerts: {isLoadingAlerts}}) => ({
    items,
    limit,
    offset,
    isLoadingAlerts
})

const MapDispatchToProps = dispatch => ({
    getPhysicalProductCategories:getPhysicalProductCategories(dispatch),
    getPhysicalProductManufacturers: getPhysicalProductManufacturers(dispatch),
    getWebinarPopUp: getWebinarPopUp(dispatch),
    getPhysicalProducts: getPhysicalProducts(dispatch),
    addMoreProducts: addMoreProducts(dispatch),
    setOffset: offset => dispatch(setOffset(offset)),
    setLoadAlerts: flag => dispatch(setLoadAlerts(flag))    // for waiting load
})
export default connect(MapStateToProps, MapDispatchToProps)(PhysicalProductPage);

