import React, { useEffect, useState } from 'react'
import { MDBIcon, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'
import { connect } from 'react-redux'
import { useAlert } from 'react-alert'

import { getPaymentProfile, createPaymentProfile, removePaymentProfile, setDefaultPaymentProfile, getCurrentUserComplete } from '../../redux/user/user.action'
import LoaderComponent from '../../components/Loader/loader.component'
import FormButton from '../../components/form-button/form-button.component'
import ModalCC from '../../components/modalCC/ModalCC'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'

import './payment-methods.style.scss'

const PaymentMethods = ({
  getPaymentProfile,
  createPaymentProfile,
  removePaymentProfile,
  setDefaultPaymentProfile,
  getCurrentUserComplete
}) => {
  // States
  const [paymentProfiles, setPaymentProfiles] = useState([])
  const [showEditForm, setShowEditForm] = useState(false)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const [methodToRemove, setMethodToRemove] = useState(false)
  const [customerInfo, setCustomerInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const [methodToDefault, setMethodToDefault] = useState(false)
  const [showDefaultConfirmation, setShowDefaultConfirmation] = useState(false)

  // Hooks
  const alert = useAlert();

  // Functions
  const getCurrentUser = async () => {
    let user = localStorage.getItem('userData');
    user = JSON.parse(user);
    if(user) {
      setLoading(true)
      const userData = await getCurrentUserComplete({email: user.email},"fromDB");
      localStorage.setItem("userData", JSON.stringify(userData));
      setLoading(false)
      return userData;
    } else {
      return false;
    }
  }

  const closeAllModals = () => {
    setShowEditForm(false)
    setShowRemoveConfirmation(false)
    setShowDefaultConfirmation(false)
  }

  const getPaymentCreditCards = async () => {
    try {
      setLoading(true)
      let user = await getCurrentUser()
      setLoading(false)
      if (user && user?.id) {
        setLoading(true)
        const result = await getPaymentProfile(user?.id)
        setLoading(false)
        if (result && result?.message === "success" && result?.result && result?.result?.paymentProfiles) {
          const { paymentProfiles } = result?.result
          setPaymentProfiles(paymentProfiles)
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      console.log(error)
      alert.error('Error getting payment methods')
      return false;
    }
  }

  const addPaymentProfile = async (data) => {
    try {
      let user = await getCurrentUser()
      if (user && user?.id) {
        setLoading(true)
        const result = await createPaymentProfile(user?.id, data)
        setLoading(false)
        if (result?.message === 'success') {
          const updateData = await getPaymentCreditCards()
          if (updateData) {
            alert.success('Payment method added successfully')
          }
          closeAllModals()
        } else {
          if(result?.result?.toLowerCase()?.includes("invalid ots token")) {
            window.location.reload();
          } else {
            alert.error(result?.result || 'Error creating payment method')
          }
          closeAllModals()
        }
      }
    } catch (error) {
      console.log(error)
      alert.error('Error creating payment method')
      closeAllModals()
    }
  }

  const deletePaymentProfile = async (id) => {
    try {
      let user = await getCurrentUser()
      if (user && user?.id) {
        setLoading(true)
        const result = await removePaymentProfile(user?.id, {
          customerPaymentProfileId: id
        })
        setLoading(false)
        if (result?.message === 'success') {
          alert.success('Payment method removed successfully')
          closeAllModals()
        } else {
          alert.error(result?.result || 'Error removing the payment method')
          closeAllModals()
        }
        window.location.reload();
        setLoading(true)
        await getPaymentCreditCards()
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      alert.error('Error removing the payment method')
      closeAllModals()
    }
  }

  const setDefaultPayment = async (item) => {
    try {

      let user = await getCurrentUser()
      if (user && user?.id) {
        setLoading(true)
        const result = await setDefaultPaymentProfile(user?.id, {
          ...item,
          authorize_customer_id: user?.authorize_customer_id
        })
        setLoading(false)
        if (result && result?.message === 'success') {
          const updateData = await getPaymentCreditCards()
          if (updateData) {
            alert.success('Default payment method set successfully')
          }
          closeAllModals()
        } else {
          alert.error(result?.result || 'Error setting the default payment method')
          closeAllModals()
        }
      }
    } catch (error) {
      console.log(error)
      alert.error('Error setting the default payment method')
      closeAllModals()
    }
  }

  // Effects
  useEffect(() => {
    async function load () {
      await getPaymentCreditCards()
    }
    load()
  }, [])

  const setCardIconByType = (type) => {
    switch (type) {
      case 'MasterCard':
        return <MDBIcon className="credit-card-icon" fab icon="cc-mastercard" />
      case 'Visa':
        return <MDBIcon className="credit-card-icon" fab icon="cc-visa" />
      case 'AmericanExpress':
        return <MDBIcon className="credit-card-icon" fab icon="cc-amex" />
      case 'Discover':
        return <MDBIcon className="credit-card-icon" fab icon="cc-discover" />
      default:
        return <MDBIcon className="credit-card-icon" fas icon="exclamation-circle" />

    }
  }

  if (loading) return (<LoaderComponent />)

  return (
    <>
      {
        showRemoveConfirmation && (
          <ConfirmationModal
            closeModal={() => setShowRemoveConfirmation(false)}
            isLoading={loading}
            message="Are you sure you want to remove this payment method?"
            yesFunction={() => deletePaymentProfile(methodToRemove)}
          />
        )
      }
      {
        showDefaultConfirmation && (
          <ConfirmationModal
            closeModal={() => setShowDefaultConfirmation(false)}
            isLoading={loading}
            message="Are you sure you want to set this payment method as default?"
            yesFunction={() => setDefaultPayment(methodToDefault)}
          />
        )
      }
      {
        showEditForm && (
          <ModalCC
            setModalShow={setShowEditForm}
            modalShow={showEditForm}
            updateCurrentUser={(value) => {
              let customValue = {
                ...value,
                profileInfo: {
                  ...customerInfo
                }
              }
              delete customValue?.profileInfo?.payment
              addPaymentProfile(value)
            }}
            profileInfo={{
              email: customerInfo?.email,
              username: customerInfo?.username,
              first_name: customerInfo?.first_name,
              last_name: customerInfo?.last_name,
              address: customerInfo?.address,
              street_address: customerInfo?.street_address,
              city: customerInfo?.city,
              state: customerInfo?.state,
              zipcode: customerInfo?.zipcode,
              phone_number: customerInfo?.phone_number,
              savedCardNum: customerInfo?.savedCardNum,
            }}
          />
        )
      }
      <div className='payment-methods-page'>
        <div className="header text-white">
          <h3>Payment Methods</h3>
        </div>
        <FormButton onClickFunc={() => {

          let user = localStorage.getItem('userData');
          const userData = JSON.parse(user);

          if (userData?.id) {
            let customValue = {
              address: userData?.address,
              city: userData?.city,
              first_name: userData?.first_name,
              last_name: userData?.last_name,
              state: userData?.state,
              zipcode: userData?.zipcode,
              country: "USA",
              phone_number: userData?.phone_number,
              email: userData?.email,
              username: userData?.username,
              street_address: userData?.street_address
            }
            setCustomerInfo(customValue)
          }
          setShowEditForm(true)
        }}
          className="btn btn-primary"
        >
          Add Payment Method
        </FormButton>
        <div className='default-payment-box'>
          <span className='default-payment-box-box'></span>
          <span className='default-payment-box-text'>Default Payment Method</span>
        </div>
        <div className="content-payment">
          {
            paymentProfiles && paymentProfiles.length > 0 ?
              (
                <MDBTable dark={false} responsive>
                  <MDBTableHead>
                    <tr className='text-light text-center'>
                      <th scope='col'>Type</th>
                      <th scope='col'>Card</th>
                      <th scope='col'>Expiration</th>
                      <th scope='col'>Default</th>
                      <th scope='col'>Remove</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {
                      paymentProfiles.map((profile, index) => (
                        <tr
                          className={`text-light text-center ${profile?.defaultPaymentProfile === true ? 'default-payment' : ''}`}
                          key={index}
                        >
                          {
                            profile?.payment?.creditCard?.cardType ? (
                              <td>{setCardIconByType(profile?.payment?.creditCard?.cardType)}</td>
                            ) :
                              (
                                <td><MDBIcon className="credit-card-icon" fas icon="exclamation-circle" /> </td>
                              )
                          }
                          <th scope='row'>{profile?.payment?.creditCard?.cardNumber}</th>
                          <td>{profile?.payment?.creditCard?.expirationDate}</td>
                          <td>
                            {
                              !profile?.defaultPaymentProfile ?
                                <MDBIcon className='payment-icon' fas icon="edit" onClick={() => {
                                  setMethodToDefault(profile)
                                  setShowDefaultConfirmation(true)
                                }
                                } />
                                :
                                null
                            }

                          </td>
                          <td>
                            <MDBIcon className='payment-icon' fas icon="trash" onClick={() => {
                              setMethodToRemove(profile?.customerPaymentProfileId)
                              setShowRemoveConfirmation(true)
                            }
                            } />

                          </td>
                        </tr>
                      ))
                    }
                  </MDBTableBody>
                </MDBTable>
              ) :
              (
                <div className="no-paymentProfiles">
                  <h3>No Payment Methods</h3>
                  <p>Click the button below to add a payment method</p>
                </div>
              )
          }
        </div>
      </div>
    </>
  )
}


const MapStateToProps = ({ }) => ({});

const MapDispatchToProps = dispatch => ({
  getPaymentProfile: getPaymentProfile(dispatch),
  createPaymentProfile: createPaymentProfile(dispatch),
  removePaymentProfile: removePaymentProfile(dispatch),
  setDefaultPaymentProfile: setDefaultPaymentProfile(dispatch),
  getCurrentUserComplete: getCurrentUserComplete(dispatch),
})

export default connect(MapStateToProps, MapDispatchToProps)(PaymentMethods);