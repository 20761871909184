import React from 'react'
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import FormButton from '../form-button/form-button.component';
import './confirmation-modal.style.scss'

const ConfirmationModal = ({
  isLoading,
  title,
  body,
  closeModal,
  yesFunction
}) => {
  return (
    <div className="modal-profile-form-confirmation">
      <div className="content">
        <div className="removeDialogWrapper">
          <div className="removeDialog">
            <div>
              <div className="close-icon">
                <i onClick={() => {
                  closeModal()
                }} className="far fa-times-circle"></i>
              </div>
              <h2 className="text-white text-center ">
                {title}
              </h2>
              <p></p>

              {body}

              <MDBRow center className="mt-4">
                <MDBCol size="8">
                  <FormButton
                    greyCol={true}
                    isLoading={isLoading}
                    onClickFunc={() => {
                      yesFunction()
                    }}>
                    <h5>Yes</h5>
                  </FormButton>
                </MDBCol>
                <MDBCol size="8" className='mt-2 lg:mt-0'>
                  <FormButton
                    isLoading={isLoading}
                    onClickFunc={() => {
                      closeModal()
                    }
                    }>
                    <h5>No</h5>
                  </FormButton>
                </MDBCol>
              </MDBRow>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal