import React, {useState, useRef, useEffect} from 'react';
import { Link, useHistory,useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { getCurrentUser, setVerifiedUser, getVerifiedStatus, complianceUpdate } from '../../redux/user/user.action';
import { updateCurrentUser } from '../../redux/user/user.action';
import './dob.style.scss';
import FormInput from '../../components/form-input/form-input.component';
import FormButton from '../../components/form-button/form-button.component';
import PasswordPolicy from '../../components/password-policy/password-policy.component';
import VerifyCodeInput from '../../components/verify-code-input/verify-code-input.component';
import FormSelect from '../../components/form-select/form-select.component';
import Input from 'react-phone-number-input/input';
import { MDBCol, MDBIcon } from 'mdbreact';
import { useAlert } from 'react-alert';
import { Helmet} from 'react-helmet';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import FormDatepicker from '../../components/form-datepicker/form-datepicker';
import FormCheckbox from '../../components/form-checkbox/form-checkbox.component';

const Dob = ({currentFontColors}) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const alert = useAlert();

    const [fontColors, setFontColors] = useState({
        header1: "white",
        header2: "white",
        paragraph: "#a3a3a3",
        form: "#a3a3a3"
    });
    useEffect(() => {
        if (currentFontColors && currentFontColors.header1_color && currentFontColors.header2_color && currentFontColors.paragraph_color && currentFontColors.form_color) {
            const h1Color = JSON.parse(currentFontColors.header1_color);
            const h2Color = JSON.parse(currentFontColors.header2_color);
            const pColor = JSON.parse(currentFontColors.paragraph_color);
            const formColor = JSON.parse(currentFontColors.form_color);
            setFontColors({
                header1: `rgba(${h1Color.r }, ${h1Color.g }, ${h1Color.b }, ${h1Color.a })`,
                header2: `rgba(${h2Color.r }, ${h2Color.g }, ${h2Color.b }, ${h2Color.a })`,
                paragraph: `rgba(${pColor.r }, ${pColor.g }, ${pColor.b }, ${pColor.a })`,
                form: `rgba(${formColor.r }, ${formColor.g }, ${formColor.b }, ${formColor.a })`                
            })
        }
    }, [currentFontColors]);

    const [values, setValues] = useState({ email:user.email, phone:'', dateOfBirth:null});

    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isDateOfBirthValid, setIsDateOfBirthValid] = useState(false);

    const [isConsentChecked, setIsConsentChecked] = useState(false);
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [notification, setNotification] = useState({terms: false, consentForTextMessages:false});

    //For Phone number regex
    const [returnUrl, setReturnUrl] = useState('');

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setReturnUrl(history?.location?.state?.returnUrl);
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(isLoading)
            return;
        setIsLoading(true);
        try {
            if(!validateDateOfBirth(values.dateOfBirth)) {
                setIsDateOfBirthValid(true);
                setIsLoading(false);
                return;
            }
            if(!validatePhoneNumber(values.phone.slice(2))) {
                setIsPhoneValid(true);
                setIsLoading(false);
                return;
            }
            if( notification.terms === false || notification.consentForTextMessages==false){
                if (notification.consentForTextMessages==false){
                    setIsConsentChecked(true)
                }
                if (notification.terms === false){
                    setIsTermsChecked(true);
                }
                setIsLoading(false);
                return
            }
            let data = {
                ...values, ...notification
            }
            await complianceUpdate(data).then((res)=>{
                localStorage.removeItem('userData');
                history.push("/signin");
            })
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
        
    }

    const validatePhoneNumber = (value) => {
        const num=value
        var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        return phoneNumberPattern.test(num);
    }

    const handlePhoneChange = (phone) =>{
        console.log("phone",phone)
        setValues({...values, phone:phone})
        if (phone){
            if(!validatePhoneNumber(phone.slice(2))) {
                setIsPhoneValid(true);
            } else {
                setIsPhoneValid(false);
            }
        }
    }

    const validateDateOfBirth = (dateOfBirth) => {
        const currentDate = moment();
        const age = currentDate.diff(dateOfBirth, 'years');
        return age >= 21
    }    

    const handleDateOfBirth  = (date) =>{
        setValues({...values, dateOfBirth:date})
        if(!validateDateOfBirth(date)) {
            setIsDateOfBirthValid(true);
        } else {
            setIsDateOfBirthValid(false);
        }
    }

    return(    
        
        
        <div className = 'dob-page'>
            <Helmet>
                {/* <!-- HTML Meta Tags --> */}
                <title>Age Gate</title>
                <link rel="canonical" href={window.location.href} />
                <meta
                name="description"
                content="Sign into Mata's Tactical Supply"
                />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content="Sign in" />
                <meta
                itemprop="description"
                content="Sign into Mata's Tactical Supply"
                />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Sign in" />
                <meta
                property="og:description"
                content="Sign into Mata's Tactical Supply"
                />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:title" content="Sign in" />
                <meta
                name="twitter:description"
                content="Sign into Mata's Tactical Supply"
                />          
            </Helmet>
            {
                <div className='dob-form'>
                    <form onSubmit={handleSubmit}>
                        <h2 className='dob-title font-weight-bold' style={{color: fontColors.header1}}>Age Gate</h2>
                        <MDBCol
                            className="p-1"
                        >              
                            <span style={{color: fontColors.header1}}>
                                Dear User, Matas is currently updating its policies to align with all relevant laws and regulations. These updates involve implementing new measures to enhance user notification and safety. As part of these ongoing improvements, we kindly ask you to provide updated information for your phone number and date of birth. By supplying this information, you not only assist us in enhancing our services but also enable us to fulfill legal obligations regarding user identification and notification. We genuinely appreciate your understanding and cooperation in this matter.
                            </span>
                        </MDBCol>
                        <FormInput type="text" name = 'username' label = 'Username' value = {user.username} isDisabled required/>
                        <FormInput type="email" name = "email" label = "E-mail" value = {user.email} isDisabled required/>

                        <FormDatepicker
                            name = 'dob' 
                            label = 'Date of birth*' 
                            value = {values.dateOfBirth} 
                            handleDateOfBirth={handleDateOfBirth} 
                            changeDateOfBirth={isDateOfBirthValid}
                        ></FormDatepicker>
                        {
                            isDateOfBirthValid && <p className="text-danger changeP">You must be 21 years or older to sign up</p>
                        }

                        <div className = 'phone-group'>
                            <Input
                                className = {`${isPhoneValid ? 'red-outline' : ''} phone-input`}
                                country = "US"
                                value={values.phone}
                                onChange={handlePhoneChange}
                                autocomplete="tel"
                                style={{color: fontColors.form}}
                                required/>                 
                            <label className = {`${values.phone ? 'shrink' : ''} ${isPhoneValid ? 'red-label' : ''} phone-input-label`}>Phone Number*</label>
                            {
                                isPhoneValid && <p className="text-danger changeP">Invalid phone number</p>
                            }
                        </div>

                        <div className = {`checkbox-container`}>
                            <FormCheckbox Notif = {notification.consentForTextMessages} alert={isConsentChecked} handleChange = {()=> 
                                setNotification({...notification, consentForTextMessages: !notification.consentForTextMessages})}/>
                            <span className="ft-500" style={{color: fontColors.paragraph}}>
                                By checking this box, you are providing consent to receive marketing text messages using an automated telephone dialing system from Matas Tactical Supply or third-parties acting on its behalf. Consent is not a condition of purchase. Msg&Data rates may apply.
                            </span>
                        </div>
                        <div className = {`checkbox-container`}>
                            <FormCheckbox Notif = {notification.terms} alert={isTermsChecked} handleChange = {()=> 
                                setNotification({...notification, terms: !notification.terms})}/>
                            <span className="ft-500" style={{color: fontColors.paragraph}}>I understand and accept all the&nbsp;<Link to='/term_condition_page' className='underline' style={{color: fontColors.header2}}>Terms and Conditions</Link>&nbsp;of this site</span>
                        </div>
                        {
                            isTermsChecked&&
                            <div className="alert alert-danger text-center" role="alert">
                                You should accept Terms&amp;Conditions!
                            </div>
                        }        
                        <br/>
                        <FormButton type="submit" isLoading={isLoading}>CONFIRM</FormButton>
                    </form>        
                </div>
            }            
        </div>
    );
};

const MapStateToProps = ({ colors: {currentFontColors}}) => ({
    currentFontColors
})

const MapDispatchToProps = dispatch => ({
    getCurrentUser: getCurrentUser(dispatch),
    setVerifiedUser: setVerifiedUser(dispatch),
    getVerifiedStatus: getVerifiedStatus(dispatch),
    updateCurrentUser: updateCurrentUser(dispatch)
})

export default connect(MapStateToProps, MapDispatchToProps)(Dob);