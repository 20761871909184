import { PhysicalProductsActionTypes } from './physical-products.types';

const INITIAL_STATE = {
    items: null,
    limit: 16,
    offset: 0
}

const PhysicalProductsReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case PhysicalProductsActionTypes.SET_PRODUCTS:
            return {
                ...state, items: action.payload
            }
        case PhysicalProductsActionTypes.ADD_MORE_PRODUCTS:
            return {
                ...state, items: { ...state.items, data: state.items.data.concat(action.payload.data)}
            }
        case PhysicalProductsActionTypes.SET_LIMIT:
            return {
                ...state, limit: action.payload
            }
        case PhysicalProductsActionTypes.SET_OFFSET:
            return {
                ...state, offset: action.payload
            }        
        default:
            return state;
    }
}

export default PhysicalProductsReducer;