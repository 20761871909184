import React, { useState, useEffect, Fragment,useRef } from "react";
import "./check-out-form.style.scss";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
// import CheckoutPayButton from '../checkout-button/checkout-button.component';
import CreditCardNumberInput from '../ccNum-input/ccNum-input.component';
import FormSelect from '../../form-select/form-select.component';
import FormCheckbox from '../../form-checkbox/form-checkbox.component';
import FormButton from '../../form-button/form-button.component';
import FormInput from '../form-input/form-input.component';
import { connect } from 'react-redux';
import { purchaseProducts, purchaseProductsForAdmin } from '../../../redux/purchase-seats/purchase-seats.action';
import { getFFLLicences, getFFLScopeInfo, saveFFLInformation } from '../../../redux/ffl-db/ffl-db.action';
import { createPaymentProfile, getCurrentUser, getPaymentProfile } from '../../../redux/user/user.action';
import LoaderComponent from '../../Loader/loader.component';

import { 
  setIsReloadCartItems, 
  setIsStartCountDown, 
  setHideRefreshedTimer, 
  changeCheckOutStatus } from '../../../redux/user/user.action';
import { setLoadAlerts } from '../../../redux/alerts/alerts.action';
import { Link, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import FflVerifyWrapper from "../../ffl-verify-wrapper/ffl-verify-wrapper.component";

const { REACT_APP_ACCEPT_CLIENT_KEY, REACT_APP_ACCEPT_API_LOGIN_ID } = process.env;

const CheckOutForm = ({
    purchaseProducts,
    purchaseProductsForAdmin,
    getFFLLicences,
    getFFLScopeInfo,
    saveFFLInformation,
    currentFontColors,
    isReloadCartItems,
    setIsReloadCartItems,
    getCurrentUser,
    setIsStartCountDown,
    setHideRefreshedTimer,
    globalCheckState,
    changeCheckOutStatus,
    setLoadAlerts,
    getPaymentProfile,
    createPaymentProfile,
    }) => {

    const alert = useAlert();
    const historyURL = useHistory();

    // const [isLoadingMap, setIsLoadingMap] = useState(true);


    {/* COMMENTED FFL SECTION */}
    // const [isLoadingMap, setIsLoadingMap] = useState(true);
    // const [userHasAlreadyFFL, setUserHasAlreadyFFL] = useState(true);
    // const [selectedFFL, setSelectedFFL] = useState(false);
    // const [confirmedFFL, setConfirmedFFL] = useState(false);
    // const [isOnSearchModeFFL, setIsOnSearchModeFFL] = useState(true);
    // const [lat,setLat]=useState(28)
    // const [lng,setLng]=useState(-82.5)
    // const [searchZipCode,setSearchZipCode]=useState('')
    // const [searchRadius,setSearchRadius]=useState('')

    {/* COMMENTED FFL SECTION */}
    // const render = (status: Status) => {
    //   return <h1>{status}</h1>;
    // };
    // const [view, setView] = useState({
    //   // detail: false,
    //   // clinic: null,
    //   center: {
    //     lat: lat,
    //     lng: lng
    //   },
    //   zoomLevel: 10
    // });
    // const [locations, setLocations] = useState(['']);
    // const [userID, setUserId] = useState(null);
    // const [fflScopeInfo, setFFLScopeInfo]=useState({
    //   fflNumber:'',
    //   licenseName:'',
    //   businessName:'',
    //   expiration:'',
    //   premiseStreet:'',
    //   premiseZipCode:'',
    //   premiseCity:'',
    //   premiseLat:'',
    //   premiseLon:'',
    //   voiceTelephone:'',
    //   verified:'',
    //   fflVerificationProof:''    
    // })
    const [contactInfo, setContactInfo] = useState({firstName:'', lastName: '', zipcode: ''});
    const [cartItems, setCartItems] = useState();
    const [subTotalPrice, setSubTotalPrice] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [totalShipping, setTotalShipping] = useState(0);

    // for using saved card
    const [isUseSavedCard, setIsUseSavedCard] = useState(false);
    const [isSaveCard, setIsSaveCard] = useState(false);
    const [savedCC, setSavedCC] = useState(null);
    const [ccSelected, setCcSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentProfiles, setPaymentProfiles] = useState(null);
    const [defaultSelected, setDefaultSelected] = useState(false);
    const [originalPaymentProfiles, setOriginalPaymentProfiles] = useState(null);
    const [use_other_payment_profile, setUseOtherPaymentProfile] = useState(false);

    const setCartItemsFunction = () => {
      const data = JSON.parse(localStorage.getItem('cartItems')); 
      if (data && data.length > 0) {
        let subtotal  = 0;
        let tax = 0;
        let shipping = 0;
        data.map( cartItem => {
          subtotal += cartItem.price * cartItem.quantity;
          if (cartItem.tax) tax += cartItem.tax * cartItem.quantity;
          if (cartItem.shipping) shipping += cartItem.shipping;
        });

        setSubTotalPrice(subtotal);
        // tax = tax.toString();
        // if (tax.indexOf(".") > -1)
        //   tax = tax.slice(0, (tax.indexOf("."))+3);
        setTotalTax(tax);
        setTotalShipping(shipping);
        setCartItems(data);
      }
      else
      {
        historyURL.push('/product', {prodType: "webinar"});
      }      
    }

    useEffect(() => {
      {/* COMMENTED FFL SECTION */}
      // async function loadFFLInfo() {
      //   const userResult = await getCurrentUser(null,"fromDB");
      //   const userFFLInfo = await getFFLScopeInfo(userResult.id)
      //   if (userResult.ffl_scope_id == null || userResult.ffl_scope_id == ''){
      //     setUserHasAlreadyFFL(false)
      //   } else {
      //     setSelectedFFL(true)
      //     setConfirmedFFL(true)
      //     setFFLScopeInfo(userFFLInfo.fflObj)
      //     setLocations([userFFLInfo.fflObj])
      //   }
      //   setIsLoadingMap(false)
      //   setUserId(userResult.id)
      //   setLat(userFFLInfo.lat)
      //   setLng(userFFLInfo.lng)
      // }
      // loadFFLInfo();

      setCartItemsFunction();
      changeCheckOutStatus(false);

      async function loadSavedCC() {
        const result = await getCurrentUser(null,"fromDB");
        if (result?.creditCardNumber) {
          setLoadAlerts(true);
          setSavedCC(result.creditCardNumber);
          //setIsUseSavedCard(true);
          setLoadAlerts(false);
        } else{
          setIsSaveCard(true);
          setPlaceholder3("Select");
        }
      }

      loadSavedCC();
    }, []);


    {/* COMMENTED FFL SECTION */}
    // useEffect(()=>{
    //   setView({
    //     // detail: false,
    //     // clinic: null,
    //     center: {
    //       lat: lat,
    //       lng: lng
    //     },
    //     zoomLevel: 11
    //   });
    //   if(selectedFFL){
    //     setLocations([fflScopeInfo])  
    //   } else {
    //     setLocations([])
    //   }
    // },[lat,lng])

    {/* COMMENTED FFL SECTION */}
    // useEffect(()=>{
    //   if(!userHasAlreadyFFL) {
    //     document.body.style.position = 'fixed';
    //   } else {
    //     document.body.style.position = 'unset';
    //   }
    // },[userHasAlreadyFFL])

    useEffect(() => {
      if (isReloadCartItems) {
        setCartItemsFunction();
        setIsReloadCartItems(false);
      }
    }, [isReloadCartItems]);

    const [fontColors, setFontColors] = useState({header1: "white", paragraph: "#a3a3a3", form: "#13a3a3"});

    useEffect(() => {
        if (currentFontColors && currentFontColors.paragraph_color && currentFontColors.header1_color && currentFontColors.form_color) {
            const pColor = JSON.parse(currentFontColors.paragraph_color);
            const h1Color = JSON.parse(currentFontColors.header1_color);
            const formColor = JSON.parse(currentFontColors.form_color);
          
            setFontColors({
                paragraph: `rgba(${pColor.r }, ${pColor.g }, ${pColor.b }, ${pColor.a })`,
                header1: `rgba(${h1Color.r }, ${h1Color.g }, ${h1Color.b }, ${h1Color.a })`,
                form: `rgba(${formColor.r }, ${formColor.g }, ${formColor.b }, ${formColor.a })`      
            })
        }
    }, [currentFontColors]);

  const paymentConfig = {
    clientKey: REACT_APP_ACCEPT_CLIENT_KEY,
    apiLoginId: REACT_APP_ACCEPT_API_LOGIN_ID,
  };

  {/* COMMENTED FFL SECTION */}
  // const handleZIPCodeChange = (zipCodeNum) => {
  //   let zipCodeRegExp = /^[0-9]*$/;

  //   if (zipCodeNum === "" || zipCodeRegExp.test(zipCodeNum)) {
  //     setSearchZipCode(zipCodeNum)
  //   }    
  // };

  {/* COMMENTED FFL SECTION */}
  // const handleRadiusCodeChange = (radius) => {
  //   let radiusRegExp = /^[0-9]*$/;

  //   if (radius === "" || radiusRegExp.test(radius)) {
  //     if(parseInt(radius)>25) return;
  //     setSearchRadius(radius)
  //   }    
  // };

  {/* COMMENTED FFL SECTION */}
  // const searchNearbyFFL = async ()=>{
  //   setLoadButton(true)
  //   setSelectedFFL(false)
  //   if(searchZipCode!=null && searchZipCode!= ''){
  //     let zipCode=searchZipCode.replace(/\D/g,'');
  //     let radius=searchRadius.replace(/\D/g,'');
  //     if(radius==""||radius==undefined) setSearchRadius(2)
  //     const result = await getFFLLicences(zipCode,radius);
  //     if(result==undefined){
  //       alert.error("Wrong Zip Code, Please try again")
  //       setLoadButton(false)
  //       return;
  //     }
  //     setIsOnSearchModeFFL(false)
  //     setLoadButton(false)
  //     setLocations(result.data)
  //     setView({
  //       // detail: false,
  //       // clinic: null,
  //       center: {
  //         lat: result.lat,
  //         lng: result.lng
  //       },
  //       zoomLevel: 10
  //     });
  //   } else {
  //     // alert("Please, insert a valid Zip Code")
  //   }
  // }

  {/* COMMENTED FFL SECTION */}
  // const saveFFL = async () =>{
  //   const result = await saveFFLInformation(fflScopeInfo,userID);
  //   if(result.data=="success"){
  //     alert.success("FFL information saved!")
  //     setConfirmedFFL(true)


  //     const userResult = await getCurrentUser(null,"fromDB");
  //     const userFFLInfo = await getFFLScopeInfo(result.id)
  //     if (userResult.ffl_scope_id == null || userResult.ffl_scope_id == ''){
  //       setUserHasAlreadyFFL(false)
  //     } else {
  //       setSelectedFFL(true) ////////////////////////////////////////
  //       setConfirmedFFL(true)
  //       setFFLScopeInfo(userFFLInfo.fflObj)
  //       setLocations([userFFLInfo.fflObj])
  //     }
  //     setIsLoadingMap(false)
  //     setUserId(userResult.id)
  //     setLat(userFFLInfo.lat)
  //     setLng(userFFLInfo.lng)
  //   }
  // }


  const [isCCBtnClicked, setIsCCBtnClicked] = useState(true);

  // for payment with Credit card
  const [creditInfo, setCreditInfo] = useState({
    cardNum: "",
    month: "",
    year: "",
    cvc: "",
    customerPaymentProfileId: "",
  });
  const [isCCInvalid, setIsCCInvalid] = useState(false);
  const [loadButton, setLoadButton] = useState(false);

  const selectOptions1 = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [optionShow1, setOptionShow1] = useState(false);
  const [placeholder1, setPlaceholder1] = useState("Month");
  const [optionShow3, setOptionShow3] = useState(false);
  const [placeholder3, setPlaceholder3] = useState("CC");

  const selectOptions2 = [
    2022,
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
    2031,
    2032,
    2033,
    2034,
    2035,
    2036,
    2037,
    2038,
    2039,
    2040,
    2041,
    2042,
    2043,
    2044,
    2045,
    2046,
    2047,
    2048,
    2049,
    2050,
    2051,
    2052,
  ];
  const [optionShow2, setOptionShow2] = useState(false);
  const [placeholder2, setPlaceholder2] = useState("Year");

  const [isCVCInvalid, setIsCVCInvalid] = useState(false);
  const [isMonthInvalid, setIsMonthInvalid] = useState(false);
  const [isYearInvalid, setIsYearInvalid] = useState(false);

  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isTermsUnchecked, setIsTermsUnchecked] = useState(false);

  // for payment with bank transfer
  // const [bankInfo, setBankInfo] = useState({bankName: '', bankNumber: '', ABANumber: '', accountName: ''});

  // for bank account types
  // const selectOptions = ["Personal Checking","Personal Savings", "Business Checking"];
  // const [optionShow, setOptionShow] = useState(false);
  // const [currentType, setCurrentType] = useState("Personal Checking");

  const creditCardNumberFormat = (value) => {
    let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let matches = v.match(/\d{4,16}/g);
    let match = (matches && matches[0]) || "";
    let parts = [];
    let len = match.length;
    for (let i = 0; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  };

  const cvcNumberFormat = (e) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setCreditInfo({ ...creditInfo, cvc: e.target.value });
    }
  };

  const validateCVCNumber = (cvcNum) => {
    let cvcRegEx = /^[0-9]{3,4}$/;
    return cvcRegEx.test(cvcNum);
  };

  const validateCreditCardNumber = (ccNum) => {
    ccNum = ccNum?.split(" ")?.join("");

    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    return (
      visaRegEx.test(ccNum) ||
      mastercardRegEx.test(ccNum) ||
      amexpRegEx.test(ccNum) ||
      discovRegEx.test(ccNum)
    );
  };

  const sendPaymentDataToAnet = () => {
    //console.log("1.4. sendPaymentDataToAnet() called");
    const authData = {};
    authData.clientKey = paymentConfig.clientKey;
    authData.apiLoginID = paymentConfig.apiLoginId;
    const cardData = {};
    cardData.cardNumber = creditInfo.cardNum.replace(/\s/g, "");
    cardData.month = creditInfo.month;
    cardData.year = creditInfo.year;
    cardData.cardCode = creditInfo.cvc;
    const secureData = {};
    secureData.authData = authData;
    secureData.cardData = cardData;
    //console.log("1.4.1 sendPaymentDataToAnet() secureData", secureData);
    return new Promise((resolve, reject) => {
      
      let lastResponse = null;

      window.Accept.dispatchData(secureData, (res) => {
        // just in case it still gets called twice, ignore the second call
        if (res == lastResponse) {
          //console.log("skipping duplicated responseHandler() call because it's the same response as the previous response");
          return;
        }
        lastResponse = res;
        //console.log("responseHandler function called", lastResponse);

        if (res.messages.resultCode === "Error") return reject(res);
        //console.log("1.4.2 sendPaymentDataToAnet() res", res);
        resolve(res);
      });
    });
  };

//**************************************************************** */
const addPaymentProfile = async (data) => {
  //console.log("data en guardado de tarjeta !!", data  )
  try {
    let user = await getCurrentUser(null,"fromDB")
    //console.log("user en guardado de tarjeta !!", user)
    if (user && user?.id) {
      if(!user.authorize_customer_id){
        let profileInfo={
          email: user?.email,
          username: user?.username,
          first_name: user?.first_name,
          last_name: user?.last_name,
          address: user?.address,
          street_address: user?.street_address,
          city: user?.city,
          state: user?.state,
          zipcode: user?.zipcode,
          phone_number: user?.phone_number,
          savedCardNum: user?.savedCardNum,
        };
        data.profileInfo = { ...data.profileInfo, ...profileInfo };
      }
      setLoading(true)
      const result = await createPaymentProfile(user?.id, data);
      //console.log("result creo el profile payment !!", result)
      setLoading(false)
      if (result?.message === 'success') {
        const updateData = await getPaymentCreditCards()
        if (updateData) {
          alert.success('Payment method added successfully')
        }
        //closeAllModals()

      } else {
        if(result?.result?.toLowerCase()?.includes("invalid ots token")) {
          //console.log('Updating localStorage...');
          let user = await getCurrentUser(null,"fromDB")
          let userDatas = JSON.parse(localStorage.getItem('userData')) || {};
          
          //console.log('AAA1Current userData:', userDatas);
          
          userDatas.authorize_customer_id = user.authorize_customer_id;
          
          //console.log('AAA2Updated userData:', userDatas);
          
          localStorage.setItem('userData', JSON.stringify(userDatas));
          
         // console.log('AAA#localStorage updated.');
          
          window.location.reload();
          setIsTermsChecked(!isTermsChecked)
          handleCreditCardCheckOut();
        } else {
          alert.error(result?.result || 'Error creating payment method')
        }
        //closeAllModals()
      }
      return result.result;
    }
  } catch (error) {
    console.log(error)
    alert.error('Error creating payment method')
    //closeAllModals()
  }
}
//*********************************************************************************** */



  const handleCreditCardNewCard = async (event) => {
    //("2.1 handleCreditCardNewCard() called event", event);
      const isValidCCNum = validateCreditCardNumber(creditInfo.cardNum);
      const isValidCVCNum = validateCVCNumber(creditInfo.cvc);

    if (
      ((creditInfo.cardNum !== "" && isValidCCNum) &&
      (creditInfo.cvc !== "" && isValidCVCNum) &&
      placeholder1 !== "Month" &&
      placeholder2 !== "Year" && !isUseSavedCard) || isUseSavedCard || use_other_payment_profile
    ) {
      setLoadButton(true);
      const products = [];
      cartItems.map ( (item, i) => {
        (item.type === "physical" || item.type === "LIP" )? products.push({ product_type: item.type, id: item.id, amount: item.quantity })
        :
        products.push({ product_type: item.type, id: item.id })
      });
      let body = {};

      try {
        
        if (isUseSavedCard){
          body = {
            products
          }
        }
        else {
          if(!use_other_payment_profile){
            //console.log("2.1.2 sendPaymentDataToAnet() called");
            const { opaqueData } = await sendPaymentDataToAnet();
            body = {
              opaqueData,
              products
            }
          } else{
            body = {
              products
            }
          }
        } 


        body.using_saved_card = isUseSavedCard;
        body.is_saving_credit_card = isSaveCard;

        if(use_other_payment_profile){
          body.use_other_payment_profile = use_other_payment_profile;
          let user = JSON.parse(localStorage.getItem('userData'));
          if(user){
            body.other_consumer_profile_id = user?.authorize_customer_id;
            body.other_payment_profile_id = creditInfo?.customerPaymentProfileId;
          }
        }
    
        let result = "";
        result = await purchaseProducts(body);
        //("2.1.3 handleCreditCardNewCard() purchaseProducts result", result, "body", body);
        if (result === "success") {
          setLoadButton(false);
          localStorage.setItem('successCartItems', localStorage.getItem('cartItems'));
          localStorage.removeItem('cartItems');
          setIsStartCountDown(false);
          setHideRefreshedTimer();
          historyURL.push("/checkout_summary");
        }
        else {
          alert.error(result);              
          setTimeout( () => {
            setLoadButton(false);
          }, 5000)
        }
      } catch (err) {
        console.log("err", err);
        /* if (err?.messages?.message[0]?.code === "E_WC_05") {
          setIsCCInvalid(true);
          setLoadButton(false);
        }
        else {
          var i = 0;
          while (i < err.messages.message.length) {
            console.log(
                err.messages.message[i].code + ": " +
                err.messages.message[i].text
              );
              // only show the user the first error message
              if (i == 0) {
                  let error = err.messages.message[i].text;
                  alert.error(error);
              }	
              i = i + 1;
          }
          setTimeout( () => {
            setLoadButton(false);
          }, 5000)
        }  */
      }
    }
     else {
      //console.log("Invalid Checkout");
      if (!isValidCCNum) setIsCCInvalid(true);
      if (!isValidCVCNum) setIsCVCInvalid(true);
      if (placeholder1 === "Month") setIsMonthInvalid(true);
      if (placeholder2 === "Year") setIsYearInvalid(true);
      return;
    }
  }

  const handleCreditCardSaved = async (event) => {
    setLoading(true);
    //onsole.log("1.1 handleCreditCardSaved() called");
      const isValidCCNum = validateCreditCardNumber(creditInfo.cardNum);
      const isValidCVCNum = validateCVCNumber(creditInfo.cvc);
      if (
        (isValidCCNum &&
        isValidCVCNum &&
        placeholder1 !== "Month" &&
        placeholder2 !== "Year" && !isUseSavedCard) || isUseSavedCard
      ) {

/*
        const profileInfo={
          first_name: "pepito",
          last_name: "perez",
          zipcode: "211022",
        }
*/
        const profileInfo={
          first_name: contactInfo.firstName,
          last_name: contactInfo.lastName,
          zipcode: contactInfo.zipcode
        }

        let datoopaco = await sendPaymentDataToAnet();
        //console.log("datoopaco", datoopaco);
        const datasale = {
          opaqueData:datoopaco.opaqueData,
          profileInfo,
        }
        const paymentProfile = await addPaymentProfile(datasale);
        //console.log("rtaCuenta!!!!!!!!!!!!!!!!!", paymentProfile);





        setLoadButton(true);
        const products = [];
        cartItems.map ( (item, i) => {
          (item.type === "physical" || item.type === "LIP" )? products.push({ product_type: item.type, id: item.id, amount: item.quantity })
          :
          products.push({ product_type: item.type, id: item.id })
        });
        let body = {};
        let { opaqueData } = await sendPaymentDataToAnet();
        try {
          if (isUseSavedCard)
            body = {
              products
            }
          else {
            
            body = {
              opaqueData,
              products
            }
          } 

 
          //const payprof= await getPaymentCreditCards();

 
          //console.log("1.1.1 handleCreditCardSaved() originalPaymentProfiles", originalPaymentProfiles, "creditInfo", creditInfo,"paymentProfiles", paymentProfiles);


          //console.log("1.1.1 handleCreditCardSaved() payprof", payprof);
          body.using_saved_card = isUseSavedCard;
          body.is_saving_credit_card = !isSaveCard;
          body.use_other_payment_profile = true;

          body.other_consumer_profile_id = paymentProfile?.customerProfileId;
          body.other_payment_profile_id = paymentProfile?.customerPaymentProfileId;
      
          const result = await purchaseProducts(body); 
          //console.log("1.1 handleCreditCardSaved() purchaseProducts result", result, "body", body);
          setLoading(false);
          if (result === "success") {
            setLoadButton(false);
            localStorage.setItem('successCartItems', localStorage.getItem('cartItems'));
            localStorage.removeItem('cartItems');

            setIsStartCountDown(false);
            setHideRefreshedTimer();
            historyURL.push("/checkout_summary");
          }
          else {
            alert.error(result);              
            setTimeout( () => {
              setLoadButton(false);
            }, 5000)
          }
          
        } catch (err) {
         /* if (err.messages.message[0].code === "E_WC_05") {
            setIsCCInvalid(true);
            setLoadButton(false);
          }
          else {
            var i = 0;
            while (i < err.messages.message.length) {
              console.log(
                  err.messages.message[i].code + ": " +
                  err.messages.message[i].text
                );
                // only show the user the first error message
                if (i == 0) {
                    let error = err.messages.message[i].text;
                    alert.error(error);
                }	
                i = i + 1;
            }
            setTimeout( () => {
              setLoadButton(false);
            }, 5000)
          } */
        }
      } else {
        if (!isValidCCNum) setIsCCInvalid(true);
        if (!isValidCVCNum) setIsCVCInvalid(true);
        if (placeholder1 === "Month") setIsMonthInvalid(true);
        if (placeholder2 === "Year") setIsYearInvalid(true);
        return;
      }
  }

  const handleCreditCardCheckOut = async (event) => {
    event.preventDefault();    
    if (loadButton) return;
    if (!isTermsChecked) {
      setIsTermsUnchecked(true);
      return
    }
    setIsCCInvalid(false);
    setIsCVCInvalid(false);
    setIsTermsUnchecked(false);
    setIsMonthInvalid(false);
    setIsYearInvalid(false);
    //console.log("1. handleCreditCardCheckOut isSaveCard", isSaveCard);
    await getPaymentCreditCards()
    if(isSaveCard) {
      //console.log("1.raiz credit card saved", isSaveCard);
      await handleCreditCardSaved();
      //await handleCreditCardNewCard();
    } else {
      //console.log("2.raiz credit card not saved", isSaveCard);
      await handleCreditCardNewCard();
    }

  };


  const selectOption = (placeholder, originalPaymentProfiles) => {
    setOptionShow3(false);
    setPlaceholder3(placeholder);
    setUseOtherPaymentProfile(true);
    const [type, number] = placeholder?.split(" ");
    const selectedProfile = originalPaymentProfiles?.filter(profile => {
      return (profile?.payment?.creditCard.cardType === type) && (profile?.payment?.creditCard.cardNumber === number);
    })
    if(selectedProfile?.length > 0) {
      setCreditInfo({
        customerPaymentProfileId: selectedProfile[0].customerPaymentProfileId,
      });
    }
    setIsSaveCard(false);
    setIsUseSavedCard(false);
  }

  const getPaymentCreditCards = async () => {
    try {
      //console.log("XXXXXXXXXgetPaymentCreditCardsXXXXXX");
      let user = localStorage.getItem('userData');
      user = JSON.parse(user);
      if (user && user?.id) {
        
        setLoading(true)
        const result = await getPaymentProfile(user?.id);
        //console.log("IMPORTANTE !! getPaymentCreditCards result", result);
        setLoading(false)
        if (result && result?.message === "success" && result?.result && result?.result?.paymentProfiles) {
          const { paymentProfiles: resultPaymentProfiles } = result?.result
          let thereIsDefaultValue = resultPaymentProfiles.filter( profile => profile?.defaultPaymentProfile === true)
          let customText = resultPaymentProfiles?.map( profile => {
            return  `${profile?.payment?.creditCard?.cardType} ${profile?.payment?.creditCard?.cardNumber}`
          })
          setOriginalPaymentProfiles(resultPaymentProfiles)
          setPaymentProfiles([...customText])
          setDefaultSelected(thereIsDefaultValue?.length > 0 ? thereIsDefaultValue[0] : null)

          if(thereIsDefaultValue?.length === 0 && resultPaymentProfiles?.length  < 2){
            selectOption(
              `${resultPaymentProfiles[0]?.payment?.creditCard?.cardType} ${resultPaymentProfiles[0]?.payment?.creditCard?.cardNumber}`,
              resultPaymentProfiles
            );
          }
          
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      console.log(error)
      alert.error('Error getting payment methods')
      return false;
    }
  }

  useEffect(() => {
    getPaymentCreditCards();
  }, [])

  
  useEffect(() => {
    if(defaultSelected){
      setPlaceholder3(`${defaultSelected?.payment?.creditCard?.cardType} ${defaultSelected?.payment?.creditCard?.cardNumber}`)
      setCreditInfo({
        customerPaymentProfileId: defaultSelected?.customerPaymentProfileId,
      });
      setUseOtherPaymentProfile(true);
    } else{
      setPlaceholder3("Select a Credit Card");
      setUseOtherPaymentProfile(false);
      setCreditInfo({
        ...creditInfo,
        customerPaymentProfileId: "",
      });
    }
  }, [paymentProfiles, defaultSelected])
  

    return (
      <FflVerifyWrapper>
        <div className="check-out-page">
          {/* COMMENTED FFL SECTION */}
          {/* {
              !userHasAlreadyFFL && <div className="removeDialogWrapper">
                  <div className="removeDialog">
                      <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={() => {
                          setUserHasAlreadyFFL(true)
                      }} />
                      <div>
                          <h2 className="text-white text-center ft35 font-weight-bold mb-4">You don't have selected an FFL, please select one before completing the checkout process
                          </h2>
                      </div>
                  </div>
              </div>
          } */}

          {
            loading && (
              <LoaderComponent />
            )
          }


          <span className="back-span" style={{color: fontColors.header1}} onClick={() => historyURL.push('/shopping_cart') }><MDBIcon icon="chevron-left" /> Back to Cart</span>
          <h2 className="font-weight-bold text-center mb-4" style={{color: fontColors.header1}}>Checkout</h2>
          <MDBRow center className="pt-4">
            <MDBCol size="12" sm="10" md="10" lg="8" xl="6" className="mb-4">
              <div className="check-out-form">
                <p className="font-weight-bold mb-4" style={{color: fontColors.header1, fontSize: '18px'}}>ORDER SUMMARY</p>
                <MDBRow>
                  <MDBCol size="5" className="text-left">
                    Product Name
                  </MDBCol>
                  <MDBCol size="5" className="text-center">
                    Price Per Item/Seat
                  </MDBCol>
                  <MDBCol size="2" className="text-right">
                    Total
                  </MDBCol>
                </MDBRow>
                <hr/>
                {
                  cartItems && cartItems.length > 0 ? cartItems.map( (item, i) => 
                  <Fragment>
                    <MDBRow key={i} className="pt-1 pb-1">
                      <MDBCol middle className="text-left" size="6">
                          <p style={{color: fontColors.header1}} className="mb-1 pb-0">
                            { item.quantity } <MDBIcon icon="times" /> { item.prodName.length > 20 ? item.prodName.slice(0,20) + "..." : item.prodName }                         
                          </p>
                          {
                            (item.type === "physical" || item.type === "LIP" ) ? <p style={{color: fontColors.paragraph, fontSize: '14px'}} className="mb-0 pb-0">Physical Product</p>
                            :
                            <p style={{color: fontColors.paragraph, fontSize: '14px'}} className="mb-0 pb-0">
                              Webinar (Seats no. - { item.seatNums.map(
                                (seatNum,i) => <span key={i}>{ i !== 0 && ', '}{seatNum+1}</span>
                                )} )
                            </p>
                          }
                      </MDBCol>
                      <MDBCol middle className="text-center" size="3" style={{color: fontColors.header1}}>
                        ${item.price.toFixed(2)}
                      </MDBCol>
                      <MDBCol middle className="text-right" size="3" style={{color: fontColors.header1}}>
                        ${(item.price * item.quantity)}
                      </MDBCol>                      
                    </MDBRow>
                    <hr/>
                  </Fragment> )
                  :
                  <Fragment>
                    <p className="text-center font-weight-bold" style={{color: fontColors.paragraph}}>No Item</p>
                    <hr/>
                  </Fragment>
                }
                <MDBRow className="pt-4">
                  <MDBCol size="4" sm="6"/>
                  <MDBCol size="8" sm="6">
                    <MDBRow className="mb-2" style={{color: fontColors.paragraph, fontSize: '14px'}}>
                      <MDBCol className="text-left" size="6">
                        Subtotal:
                      </MDBCol>
                      <MDBCol className="text-right" size="6">
                        ${subTotalPrice.toFixed(2)}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="mb-2" style={{color: fontColors.paragraph, fontSize: '14px'}}>
                      <MDBCol className="text-left" size="6">
                        Taxes:
                      </MDBCol>
                      <MDBCol className="text-right" size="6">
                        ${totalTax.toFixed(2)}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="mb-2" style={{color: fontColors.paragraph, fontSize: '14px'}}>
                      <MDBCol className="text-left" size="6">
                        Shipping:
                      </MDBCol>
                      <MDBCol className="text-right" size="6">
                        ${totalShipping.toFixed(2)}
                      </MDBCol>
                    </MDBRow>
                    <hr className="mt-3 mb-3"/>
                    <MDBRow className="mb-2 font-weight-bold" style={{color: fontColors.header1, fontSize: '17px'}}>
                      <MDBCol className="text-left" size="6">
                        TOTAL:
                      </MDBCol>
                      <MDBCol className="text-right" size="6">
                        ${(subTotalPrice + totalTax + totalShipping).toFixed(2)}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
            {/* <MDBCol size="12" sm="10" md="10" lg="8" xl="6" className="mb-4">
              <div className="check-out-form">
                <p className="font-weight-bold mb-4" style={{color: fontColors.header1, fontSize: '18px'}}>FFL INFORMATION</p>
                <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-12">
                  {
                    selectedFFL ?
                    <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
                      {
                        (fflScopeInfo.businessName!=null && fflScopeInfo.businessName!='') &&
                        <MDBRow>
                          <MDBCol sm="12" md="12" lg="4" xl="4" className="mb-4">
                            <span style={{color: fontColors.paragraph, fontSize: '16px'}}>Selected FFL: </span>
                          </MDBCol>
                          <MDBCol sm="12" md="12" lg="6" xl="6" className="mb-4">
                            <p style={{color: fontColors.header1, fontSize: '20px'}} className="mb-1 pb-0">
                              {fflScopeInfo.businessName}
                            </p>
                          </MDBCol>
                        </MDBRow>
                      }
                      <MDBRow >
                        <MDBCol sm="12" md="12" lg="4" xl="4" className="mb-4">
                          <span style={{color: fontColors.paragraph, fontSize: '16px'}}>License Name: </span>
                        </MDBCol>
                        <MDBCol sm="12" md="12" lg="6" xl="6" className="mb-4">
                          <p style={{color: fontColors.header1, fontSize: '20px'}} className="mb-1 pb-0">
                            {fflScopeInfo.licenseName}
                          </p>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="6" md="6" lg="4" xl="4" className="mb-4">
                          <span style={{color: fontColors.paragraph, fontSize: '16px'}}>Expiration Date: </span>
                        </MDBCol>
                        <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">
                          <p style={{color: fontColors.header1, fontSize: '18px'}} className="mb-1 pb-0">
                            {fflScopeInfo.expiration}
                          </p>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="6" md="6" lg="4" xl="4" className="mb-4">
                          <span style={{color: fontColors.paragraph, fontSize: '16px'}}>Address: </span>
                        </MDBCol>
                        <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">
                          <p style={{color: fontColors.header1, fontSize: '18px'}} className="mb-1 pb-0">
                            {fflScopeInfo.premiseStreet}, {fflScopeInfo.premiseCity}, {fflScopeInfo.premiseZipCode}
                          </p>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="6" md="6" lg="4" xl="4" className="mb-4">
                          <span style={{color: fontColors.paragraph, fontSize: '16px'}}>Contact Number: </span>
                        </MDBCol>
                        <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">
                          <p style={{color: fontColors.header1, fontSize: '18px'}} className="mb-1 pb-0">
                            {fflScopeInfo.voiceTelephone==null?"N/A":"(+1) "+fflScopeInfo.voiceTelephone}
                          </p>
                        </MDBCol>
                      </MDBRow>
                      {
                        !confirmedFFL &&
                        <MDBCol size="6" sm="12" md="12" lg="12" xl="12" className="mb-4">
                          <FormButton onClickFunc={()=>saveFFL()}>Save FFL</FormButton>
                        </MDBCol>
                      }

                    </MDBCol>
                    :
                    <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
                      <MDBRow>
                        <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
                          <h2 style={{color: "white", justifyContent:'center', textAlign:"center" ,fontSize: '20px'}}>You have not selected any FFL! </h2>
                          <h2 style={{color: "white", justifyContent:'center', textAlign:"center" ,fontSize: '20px'}}>Use the map to search and pick one</h2>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>                  
                  }
                  {
                    isOnSearchModeFFL ?
                    <MDBCol size="12" sm="12" md="12" lg="6" xl="12" className="mb-4">
                      <MDBRow className="">
                        <MDBCol size="12" sm="12" md="12" lg="6" xl="6">
                          <MDBRow>
                            <p className="font-weight-bold mb-2" style={{color: fontColors.paragraph, fontSize: '16px', margin:0}}>Search by ZIP Code</p>
                            <FormInput 
                                type="text"
                                style={{margin:0}}
                                value = {searchZipCode}
                                placeHolder="Enter your zip code"
                                handleChange = {(event) => handleZIPCodeChange(event.target.value)} 
                            />
                          </MDBRow>
                        </MDBCol>
                        <MDBCol size="12" sm="12" md="12" lg="6" xl="6">
                          <MDBRow>
                            <p className="font-weight-bold mb-2" style={{color: fontColors.paragraph, fontSize: '16px', margin:0}}>Radius: (default 2 miles)</p>
                            <FormInput 
                                type="text"
                                style={{margin:0}}
                                value = {searchRadius}
                                handleChange = {(event) => handleRadiusCodeChange(event.target.value)}
                                placeHolder =  "Enter the radius to look around"
                            />
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                      <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
                        <FormButton isLoading={loadButton} onClickFunc={()=>searchNearbyFFL()}>Search</FormButton>
                      </MDBCol>
                    </MDBCol>
                    :
                    <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
                      <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
                        <FormButton onClickFunc={()=>{setIsOnSearchModeFFL(true); setSelectedFFL(false)}}>Search again?</FormButton>
                      </MDBCol>
                    </MDBCol>                  
                  }
                  {
                    <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
                      {isLoadingMap?
                        <div className="wait-loading">
                          <MDBIcon className="loadIcon" icon="sync-alt" />
                        </div>
                        :                    
                        <div style={{ display: "flex", height: "100%" }}>
                          <Wrapper apiKey={""} render={render}>
                            <Map
                              locations={locations} view={view} setFFLScopeInfo={setFFLScopeInfo} setSelectedFFL={setSelectedFFL}
                            >
                            </Map>
                          </Wrapper>
                        </div>
                      }
                    </MDBCol>                  
                  }
                </MDBCol>
              </div>
            </MDBCol> */}
            <MDBCol size="12" sm="10" md="10" lg="8" xl="6" className="mb-4">
              <div className="check-out-form">
                  <p className="font-weight-bold mb-4" style={{color: fontColors.header1, fontSize: '18px'}}>CARD DETAILS</p>

                  <form onSubmit={handleCreditCardCheckOut}>
                    <MDBRow className="mt-4" between>
                      <MDBCol size="12" md="6" className="mb-2">
                          {
                            paymentProfiles && (
                              <FormSelect 
                              forPayment={true}
                              options={paymentProfiles}
                              //disabled={isUseSavedCard}
                              showSelectBox={() => setOptionShow3(!optionShow3)}
                              selectOption={(event) => {
                                setOptionShow3(false);
                                setPlaceholder3(event.target.id);
                                setUseOtherPaymentProfile(true);
                                const [type, number] = event.target?.id?.split(" ");
                                const selectedProfile = originalPaymentProfiles.filter(profile => {
                                  return (profile?.payment?.creditCard.cardType === type) && (profile?.payment?.creditCard.cardNumber === number);
                                })
                                if(selectedProfile.length > 0) {
                                  //console.log("selectI: 2",selectedProfile[0]);
                                  setCreditInfo({
                                    customerPaymentProfileId: selectedProfile[0].customerPaymentProfileId,
                                  });
                                }
                                setIsSaveCard(false);
                                setIsUseSavedCard(false);
                              }}
                              optionShow={optionShow3}
                              placeholder={placeholder3}
                              //isInvalid={isMonthInvalid}
                              />
                            )
                          }

                          {/* <FormCheckbox
                            Notif={isUseSavedCard}
                            disabled={!savedCC}
                            handleChange={() => {
                              if (savedCC) {
                                setIsUseSavedCard(!isUseSavedCard);
                                !isUseSavedCard && setIsSaveCard(false);
                              }
                              }
                            }
                          /> */}
                          {/* <p style={{ color: fontColors.paragraph }}>                       
                              Use saved card {savedCC ? `(${savedCC})` : '(No card)'}
                          </p> */}
                      </MDBCol>
                      <MDBCol size="12" md="6" className="mb-2">
                        <div className="checkbox-container">
                          <FormCheckbox
                            Notif={isSaveCard}
                            disabled={isUseSavedCard}
                            handleChange={() => {
                              setIsSaveCard(!isSaveCard)
                              setPlaceholder3("Select a Credit Card");
                            }}
                          />
                          <p style={{ color: fontColors.paragraph }}>                       
                            Save card to profile
                          </p>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    {
                      !isSaveCard || !placeholder3 || placeholder3.includes("Select") && 
                      <Fragment>
                        
                        <p className="label">First Name</p>
                        <FormInput 
                            type="text" 
                            placeHolder = "First Name" 
                            value = {contactInfo.firstName} 
                            handleChange = {(event) => setContactInfo({ ...contactInfo, firstName: event.target.value})} 
                            required/>

                        <p className="label">Last Name</p>
                        <FormInput 
                            class="form-input"
                            type="text"
                            value = {contactInfo.lastName} 
                            handleChange = {(event) => setContactInfo({ ...contactInfo, lastName: event.target.value})} 
                            placeHolder="Last Name"
                            required/>

                        <p className="label">Zip Code</p>
                        <FormInput 
                            class="cvc-input"
                            type="text"
                            value = {contactInfo.zipcode}
                            handleChange = {(event) => setContactInfo({ ...contactInfo, zipcode: event.target.value})} 
                            placeHolder="Enter your zip code"
                            required
                            />

                        <p className="label">Card Number</p>
                        <CreditCardNumberInput
                          value={creditInfo.cardNum}
                          disabled={isUseSavedCard}
                          handleChange={(event) =>
                            setCreditInfo({
                              ...creditInfo,
                              cardNum: creditCardNumberFormat(event.target.value),
                            })
                          }
                          forCC={true}
                          style={{ color: fontColors.form }}
                          placeHolder="Card Number"
                          isInvalidNum={isCCInvalid}
                          autoComplete="cc-number"
                        />
                        {isCCInvalid && (
                          <p className="text-danger invalidNum">Invalid CC Number</p>
                        )}
                        <MDBRow className="mb-4 mt-4">
                          <MDBCol size="6" sm="6" md="6" lg="4">
                            <p className="label">Expiration Date</p>
                            <FormSelect
                              forPayment={true}
                              options={selectOptions1}
                              disabled={isUseSavedCard}
                              showSelectBox={() => !isUseSavedCard && setOptionShow1(!optionShow1)}
                              selectOption={(event) => {
                                setOptionShow1(false);
                                setPlaceholder1(event.target.id);
                                setCreditInfo({
                                  ...creditInfo,
                                  month:
                                    selectOptions1.indexOf(event.target.id) + 1 < 10
                                      ? "0" +
                                        (
                                          selectOptions1.indexOf(event.target.id) + 1
                                        ).toString()
                                      : (
                                          selectOptions1.indexOf(event.target.id) + 1
                                        ).toString(),
                                });
                              }}
                              optionShow={optionShow1}
                              placeholder={placeholder1}
                              isInvalid={isMonthInvalid}
                            />
                            {isMonthInvalid && (
                              <p className="text-danger invalidNum">Invalid Month</p>
                            )}
                          </MDBCol>
                          <MDBCol size="6" sm="6" md="6" lg="4">
                            <p className="label">&nbsp;</p>
                            <FormSelect
                              forPayment={true}
                              options={selectOptions2}
                              disabled={isUseSavedCard}
                              showSelectBox={() => !isUseSavedCard && setOptionShow2(!optionShow2)}
                              selectOption={(event) => {
                                setOptionShow2(false);
                                setPlaceholder2(event.target.id);
                                setCreditInfo({
                                  ...creditInfo,
                                  year: event.target.id.slice(2),
                                });
                              }}
                              optionShow={optionShow2}
                              placeholder={placeholder2}
                              isInvalid={isYearInvalid}
                            />
                            {isYearInvalid && (
                              <p className="text-danger invalidNum">Invalid Year</p>
                            )}
                          </MDBCol>
                          <MDBCol size="12" sm="12" md="12" lg="4">
                            <p className="label">CVC</p>

                            <input
                              className={`${isCVCInvalid ? "red-outline" : ""} cvc-input`}
                              style={{ color: fontColors.form }}
                              type="text"
                              placeholder="CVC"
                              value={creditInfo.cvc}
                              onChange={(event) => cvcNumberFormat(event)}
                              maxLength="4"
                              autoComplete="cc-csc"
                              disabled={isUseSavedCard}
                            />
                            {isCVCInvalid && (
                              <p className="text-danger invalidNum">Invalid CVC Number</p>
                            )}
                          </MDBCol>
                        </MDBRow>
                      </Fragment>
                    }
                    <div className="checkbox-container  mb-4">
                      <FormCheckbox
                        Notif={isTermsChecked}
                        handleChange={() => setIsTermsChecked(!isTermsChecked)}
                      />
                      <p style={{ color: fontColors.paragraph }}>
                        I confirm that I have understood I am purchasing the products and
                        I have read the &nbsp;
                        <Link to="/term_condition_page" className="underline">
                          Terms and Conditions
                        </Link>
                      </p>
                    </div>
                    {isTermsUnchecked && (
                      <div className="alert alert-danger text-center" role="alert">
                        You should accept Terms&amp;Conditions!
                      </div>
                    )}
                    <FormButton isDisabled={ !cartItems || cartItems.length < 0 || globalCheckState} isLoading={loadButton} type="submit">
                      CHECKOUT
                    </FormButton>
                  </form>
                  {/* :
                      <form onSubmit={handleBankTransfer}>
                          <p className="label">Bank Name</p>
                          <CreditCardNumberInput value = {bankInfo.bankName} handleChange = {(event) => setBankInfo({ ...bankInfo, bankName: event.target.value})} forCC={false} placeHolder="bank name" required/>
                          
                          <p className="label mt-2">Bank Account Number</p>
                          <CreditCardNumberInput value = {bankInfo.bankNumber} handleChange = {(event) => setBankInfo({ ...bankInfo, bankNumber: event.target.value})} forCC={false} placeHolder="bank account number" required/>
                          
                          <p className="label mt-2">ABA Routing Number</p>
                          <CreditCardNumberInput value = {bankInfo.ABANumber} handleChange = {(event) => setBankInfo({ ...bankInfo, ABANumber: event.target.value})} forCC={false} placeHolder="ABA number" required/>
                          
                          <p className="label mt-2">Name On Account</p>
                          <CreditCardNumberInput value = {bankInfo.accountName} handleChange = {(event) => setBankInfo({ ...bankInfo, accountName: event.target.value})} forCC={false} placeHolder="account name" required/>
                          
                          <p className="label mt-2">Bank Account Type</p>
                          <FormSelect forPayment = {true} options={selectOptions} showSelectBox={()=>setOptionShow(!optionShow)} selectOption={(event)=>{
                              setOptionShow(false);
                              setCurrentType(event.target.id);
                          }} optionShow={optionShow} placeholder={currentType}/>
                          <div className = 'checkbox-container  mb-4 mt-4'>
                              <FormCheckbox Notif = {isTermsChecked} handleChange = {()=> 
                                  setIsTermsChecked(!isTermsChecked)}/>
                              <p className="grey-text">I confirm that I have understood I am buying a webinar and I have read the &nbsp;<Link to='/term_condition_page' className='underline'>Terms and Conditions</Link></p>
                          </div>
                          {
                              isTermsUnchecked&&<div className="alert alert-danger text-center" role="alert">
                                              You should accept Terms&amp;Conditions!
                                          </div>
                          } 
                          <FormButton isLoading={loadButton} type="submit">CHECKOUT</FormButton>
                      </form>
                  } */}
                  <br/>
                  <p style={{color: fontColors.header1, fontSize: '20px'}} className="text-right mt-4 pt-4">Thank you for choosing Mata’s Tactical Supply</p>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </FflVerifyWrapper>
  );
};

const MapStateToProps = ({ colors: { currentFontColors }, user: {isReloadCartItems, globalCheckState} }) => ({
  currentFontColors,
  isReloadCartItems,
  globalCheckState
});

const MapDispatchToProps = (dispatch) => ({
  getFFLScopeInfo:getFFLScopeInfo(dispatch),
  saveFFLInformation:saveFFLInformation(dispatch),
  getFFLLicences:getFFLLicences(dispatch),
  purchaseProducts: purchaseProducts(dispatch),
  purchaseProductsForAdmin: purchaseProductsForAdmin(dispatch),
  setIsReloadCartItems: flag => dispatch(setIsReloadCartItems(flag)),
  getCurrentUser: getCurrentUser(dispatch),
  setIsStartCountDown: flag => dispatch(setIsStartCountDown(flag)),
  setHideRefreshedTimer: () => dispatch(setHideRefreshedTimer()),
  changeCheckOutStatus: state => dispatch(changeCheckOutStatus(state)),
  setLoadAlerts: flag => dispatch(setLoadAlerts(flag)) ,
  getPaymentProfile: getPaymentProfile(dispatch),
  createPaymentProfile: createPaymentProfile(dispatch),
});

export default connect(MapStateToProps, MapDispatchToProps)(CheckOutForm);
