import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Auth, Storage } from "aws-amplify";
import { connect } from "react-redux";
import {
  updateCurrentUser,
  getCurrentUser,
  removeFFLFromUser,
} from "../../redux/user/user.action";
import { setLoadAlerts } from "../../redux/alerts/alerts.action";
import FormInput from "../../components/form-input/form-input.component";
import FormButton from "../../components/form-button/form-button.component";
import FormSelect from "../../components/form-select/form-select.component";
import SavedCCInfo from "../../components/saveCCInfo/saveCCInfo.component";
import Input from "react-phone-number-input/input";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { MDBIcon } from "mdbreact";
import NotificationSelect from "../../components/notification-select/notification-select.component";
import "./myaccount-page.style.scss";
import VerifyCodeInput from "../../components/verify-code-input/verify-code-input.component";
import Autocomplete from "react-google-autocomplete";
import { useAlert } from "react-alert";
import { getImageFromS3 } from "../../utils/services/s3";
import { Helmet } from "react-helmet";
import { MDBRow, MDBCol } from "mdbreact";
import {
  getFFLLicences,
  getFFLScopeInfo,
  saveFFLInformation,
  getFFLLicencesFiltered
} from "../../redux/ffl-db/ffl-db.action";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from "../../components/payment-form/check-out-form/Map";
import moment from 'moment-timezone';
import { isValidLoaExpirationDate } from "../../utils/fflUtils";

const MyAccountPage = ({
  updateCurrentUser,
  getCurrentUser,
  currentUser,
  currentFontColors,
  setLoadAlerts,
  getFFLLicences,
  getFFLScopeInfo,
  saveFFLInformation,
  getFFLLicencesFiltered,
  removeFFLFromUser
}) => {
  const [fontColor, setFontColor] = useState({
    content: "white",
    header: "a3a3a3",
  });
  const [selects, setSelects] = useState("");

  const [fontColors, setFontColors] = useState({
    header1: "white",
    header2: "white",
    form: "#a3a3a3",
    paragraph: "#a3a3a3",
  });

  {
    /* COMMENTED FFL SECTION */
  }
  const [isLoadingMap, setIsLoadingMap] = useState(true);
  const [userHasAlreadyFFL, setUserHasAlreadyFFL] = useState(true);
  const [isFFLexpired, setisFFLexpired] = useState(false);
  const [userHasAFFL, setUserHasFFL] = useState(true);
  const [startEmpyFFL, setStartEmpyFFL] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [selectedFFL, setSelectedFFL] = useState(false);
  const [confirmedFFL, setConfirmedFFL] = useState(false);
  const [isOnSearchModeFFL, setIsOnSearchModeFFL] = useState(true);
  const [lat, setLat] = useState(28);
  const [lng, setLng] = useState(-82.5);
  const [searchZipCode, setSearchZipCode] = useState("");
  const [searchRadius, setSearchRadius] = useState("");
  const [isvisibleTable, setIsvisibleTable] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [phoneNumberModificaton, setPhoneNumberModificaton] = useState(false);

  {
    /* COMMENTED FFL SECTION */
  }
  const render = (status) => {
    return <h1>{status}</h1>;
  };
  const [view, setView] = useState({
    // detail: false,
    // clinic: null,
    center: {
      lat: lat,
      lng: lng,
    },
    zoomLevel: 10,
  });
  const [locations, setLocations] = useState([""]);
  const [searchType, setSearchType] = useState('zipcode')
  const [emptyFields, setEmptyFields] = useState(false)
  const [errorLength, setErrorLength] = useState(false)
  const [searchName, setSearchName] = useState('')
  const [searchNumber, setSearchNumber] = useState('')
  const [licenseffl, setLicenseffl] = useState([""]);
  const [loadButton, setLoadButton] = useState(false);
  const [clicList, setClicList] = useState(null);
  const [click, setClick] = useState(false);
  const [timer, setTimer] = useState(true);
  const [userID, setUserId] = useState(null);
  const [fflScopeInfo, setFFLScopeInfo] = useState({
    fflNumber: "",
    licenseName: "",
    businessName: "",
    expiration: "",
    loaExpirationDate: "",
    premiseStreet: "",
    premiseZipCode: "",
    premiseCity: "",
    premiseLat: "",
    premiseLon: "",
    voiceTelephone: "",
    verified: "",
    fflVerificationProof: "",
    premiseState: ""
  });

  const hasLoaExpirationDate = useMemo(() => isValidLoaExpirationDate(fflScopeInfo), [fflScopeInfo])

  useEffect(() => {
    {
      /* COMMENTED FFL SECTION */
    }
    setSearchType('zipcode')
    async function loadFFLInfo() {
      const userResult = await getCurrentUser(null, "fromDB");
      const userFFLInfo = await getFFLScopeInfo(userResult.id);
           
      try {
        const expirationDate = isValidLoaExpirationDate(userFFLInfo.fflObj)? userFFLInfo.fflObj.loaExpirationDate : userFFLInfo.fflObj.expiration
        if( (Date.parse(expirationDate)) < (Date.parse(new Date())) ){
          alert.error('FFL EXPIRED');
          setisFFLexpired(true);
        }
      } catch (error) {
        console.log("🚀 ~ file: myaccount-page.component.jsx:125 ~ loadFFLInfo ~ error", error)
        
      }    
  
      if (userResult.ffl_scope_id == null || userResult.ffl_scope_id == "") {
        setUserHasAlreadyFFL(false);
        setUserHasFFL(false)
      } else {
        setSelectedFFL(true);
        setConfirmedFFL(true);
        try {
          setFFLScopeInfo(userFFLInfo.fflObj);
          setLocations([userFFLInfo.fflObj]);
          
        } catch (error) {
          console.log("🚀 ~ file: myaccount-page.component.jsx:124 ~ loadFFLInfo ~ error", error)
          
        }
       
      }
   
      setIsLoadingMap(false);
     // setIsvisibleTable(false)
      setUserId(userResult.id);
      /* if(userFFLInfo != undefined)
      {
        setLat(userFFLInfo.lat);
        setLng(userFFLInfo.lng);
      } */
      //   setSelectedFFL(false )
    }
    loadFFLInfo();

  }, []);

  useEffect(() => {
    if (
      currentFontColors &&
      currentFontColors.header1_color &&
      currentFontColors.header2_color &&
      currentFontColors.form_color &&
      currentFontColors.paragraph_color
    ) {
      const h1Color = JSON.parse(currentFontColors.header1_color);
      const h2Color = JSON.parse(currentFontColors.header2_color);
      const form = JSON.parse(currentFontColors.form_color);
      const pColor = JSON.parse(currentFontColors.paragraph_color);

      setFontColors({
        header1: `rgba(${h1Color.r}, ${h1Color.g}, ${h1Color.b}, ${h1Color.a})`,
        header2: `rgba(${h2Color.r}, ${h2Color.g}, ${h2Color.b}, ${h2Color.a})`,
        form: `rgba(${form.r}, ${form.g}, ${form.b}, ${form.a})`,
        paragraph: `rgba(${pColor.r}, ${pColor.g}, ${pColor.b}, ${pColor.a})`,
      });
    }
  }, [currentFontColors]);

  {
    /* COMMENTED FFL SECTION */
  }

  useEffect(() => {
    setView({
      // detail: false,
      // clinic: null,
      center: {
        lat: lat,
        lng: lng,
      },
      zoomLevel: 11,
    });
    if (selectedFFL) {
      setLocations([fflScopeInfo]);
    } else {
      setLocations([]);
    }
  }, [lat, lng]);


  useEffect(() => {

    if(selectedFFL && userHasAFFL && startEmpyFFL && timer){
     
      setTimeout(() => {
        setTimer(false)
      }, 5000);

    }
  }, [userHasAFFL, selectedFFL, startEmpyFFL ]);

  {
    /* COMMENTED FFL SECTION */
  }
  useEffect(() => {
    if (!userHasAlreadyFFL) {
      document.body.style.position = "unset";
    } else {
      document.body.style.position = "unset";
    }
  }, [userHasAlreadyFFL]);

  useEffect(() => {
    if (searchType === 'zipcode') {
      if (searchZipCode === '' || searchZipCode === null) {
        setEmptyFields(true)
      } else {
        setEmptyFields(false)
      }
    } else if (searchType === 'name') {
      if (searchName === '' || searchName === null) {
        setEmptyFields(true)
      } else {
        setEmptyFields(false)
      }
    } else if (searchType === 'number') {
      if (searchNumber === '' || searchNumber === null) {
        setEmptyFields(true)
      } else {
        setEmptyFields(false)
      }
    }
  }, [searchZipCode, searchName, searchNumber, searchType])
  {
    /* COMMENTED FFL SECTION */
  }
  const handleZIPCodeChange = (zipCodeNum) => {
    let zipCodeRegExp = /^[0-9]*$/;
    if (zipCodeNum === "" || zipCodeRegExp.test(zipCodeNum)) {
      setSearchZipCode(zipCodeNum);
    }
    else {
      setErrorLength(true)
    }
  };

  {
    /* COMMENTED FFL SECTION */
  }
  const handleRadiusCodeChange = (radius) => {
    let radiusRegExp = /^[0-9]*$/;
    if (radius === "" || radiusRegExp.test(radius)) {
      if (parseInt(radius) > 25) return;
      setSearchRadius(radius);
    }
  };

  const handleFFLNameChange = name => {
    let nameExp =/^[\S][\s\S]*$/;
    if (name === "" || nameExp.test(name)) {
      setSearchName(name);
    }
    else {
      setErrorLength(true)
    }
   //} 
  }

  const handleFFLNumberChange = number => {
    let numberChange  =/^[a-zA-Z0-9\S]*$/;
    if (number === "" || numberChange.test(number)) {
     
      setSearchNumber(number);
    }
    else {
      setErrorLength(true)
    }
   
  }

  {
    /* COMMENTED FFL SECTION */
  }
  const searchNearbyFFL = async () => {
    setSearchButton(true);
    setLoadButton(true);
    let zipCode = ''
    let radius = 10
    setSelectedFFL(false);
    if (searchType === 'zipcode') {
      if (searchZipCode != null && searchZipCode != "") {
         zipCode = searchZipCode.replace(/\D/g, "");
         radius = searchRadius.toString().replace(/\D/g, "");
        if (radius == "" || radius == undefined) 
        {
          radius = 10
          setSearchRadius(10)
        }
        
        /* const result = await getFFLLicences(zipCode, radius);
        setLicenseffl(result); */
  
        // Object.values(obj)
  
      }
    }
    
    try {

      const result = await getFFLLicencesFiltered({
        zipcode: zipCode,
        radius,
        name: searchName,
        number: searchNumber,
        type: searchType,
      })
      setIsOnSearchModeFFL(false);
      // setLoadButton(false);
     // setLocations(result.data);
      setIsvisibleTable(true);
      if (result == undefined || result?.data?.length === 0) {
        alert.error("Wrong " +searchType+", Please try again");
  
        if (searchType=='zipcode') {
          alert.error("Wrong Zip Code, Please try again");
        } else {
          alert.error("Wrong "+ searchType+" Please try again");
  
        } 
        
        setErrorLength(true)
        setLoadButton(false);
  
      }

      setView({
        // detail: false,
        // clinic: null,
        center: {
          lat: result.lat,
          lng: result.lng,
        },
        zoomLevel: 10,
      });
       
      if (result?.data?.length === 0) {
     
        setErrorLength(true)
        setLoadButton(false)
        setLicenseffl([])
        return
      }
      setLicenseffl(result)
      if (result === undefined) {
      setLoadButton(false)
        setLicenseffl([])
        setErrorLength(true)
        return
      }
      
      setErrorLength(false)
      setIsOnSearchModeFFL(false)
      setLoadButton(false)
      setLocations(result.data)
      setIsvisibleTable(true)
      setView({
        
        center: {
          lat: searchType === 'zipcode' ? result.lat : lat,
          lng: searchType === 'zipcode' ? result.lng : lng,
        },
        zoomLevel: 10,
      })
    } catch (error) {
      setLoadButton(false)
    }
 
  };
    
  const saveFFL = async () => {
    const result = await saveFFLInformation(fflScopeInfo, userID);

    if (result.data == "success") {

      alert.success("FFL information saved!");
      setConfirmedFFL(true);
      const userResult = await getCurrentUser(null, "fromDB");
      const userFFLInfo = await getFFLScopeInfo(result.id);

      const updateLocalStorageUserData = {
        ...userResult,
        ffl_scope_id: userResult?.ffl_scope_id,
      };

      if(userResult){
        localStorage.setItem("userData", JSON.stringify(updateLocalStorageUserData));
      }
      
      if (userResult.ffl_scope_id == null || userResult.ffl_scope_id == "") {
        setUserHasAlreadyFFL(false);
        setUserHasFFL(false)   
      } else {
        setSelectedFFL(true); ////////////////////////////////////////
        setConfirmedFFL(true);
        setUserHasFFL(true);
       setFFLScopeInfo(userFFLInfo.fflObj);

        if((click && !userHasAFFL) ||   (click && isFFLexpired) ){
    
          setStartEmpyFFL(true)
        }
       
        setLocations([userFFLInfo.fflObj]);

        const expirationDate = isValidLoaExpirationDate(userFFLInfo) ? userFFLInfo.fflObj.loaExpirationDate : userFFLInfo.fflObj.expiration

        if(Date.parse(expirationDate) > Date.parse(new Date())){
          setisFFLexpired(false);
        }

      }
      
      setIsLoadingMap(false);
      setIsvisibleTable(false);
      setUserId(userResult.id);
      setLat(userFFLInfo.lat);
      setLng(userFFLInfo.lng);
    }
  };

  const alert = useAlert();

  const userData = JSON.parse(localStorage.getItem("userData"));

  const [profilePicURL, setProfilePicURL] = useState();

  const [credential, setCredential] = useState({
    username: userData ? userData.username : "",
    email: userData ? userData.email : "",
    firstname: userData ? userData.first_name : "",
    lastname: userData ? userData.last_name : "",
    address: userData ? userData.address : "",
    street_address:
      userData && userData.street_address ? userData.street_address : "",
    city: userData && userData.city ? userData.city : "",
    state: userData && userData.state ? userData.state : "",
    selected_ffl_id:
      userData && userData.selected_ffl_id ? userData.selected_ffl_id : "",
    zipcode: userData && userData.zipcode ? userData.zipcode : "",
    confirmcode: "",
    isVerified: userData ? userData.is_verified : false,
    savedCardNum: userData ? userData.creditCardNumber : "",
    is_Delete: false,
  });

  const [phone, setPhone] = useState(userData ? userData.phone_number : null);

  const [isLoading, setIsLoading] = useState(false);

  //For resend code
  const [isResend, setIsResend] = useState(false);

  // For email change

  const [codeSent, setCodeSent] = useState({ email: false, phone: false });
  const [verifyMethod, setVerifyMethod] = useState(
    (userData && userData.verified_method) || false
  );
  const [isEmailVerified, setIsEmailVerified] = useState(
    (userData && userData.is_email_verified) || false
  );
  const [isPhoneVerified, setIsPhoneVerified] = useState(
    (userData && userData.is_phone_verified) || false
  );
  const [changeEmail, setChangeEmail] = useState(false);
  const [changePhone, setChangePhone] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);

  // For notification
  const [notification, setNotification] = useState({
    product: false,
    webinar: false,
  });
  const [prodNot, setProdNot] = useState({ phone: false, email: false });
  const [webinarNot, setWebinarNot] = useState({ phone: false, email: false });
  const [show, setShow] = useState(true);

  let isProd = false,
    isWebinar = false,
    isProdPhone = false,
    isProdEmail = false,
    isWebinarPhone = false,
    isWebinarEmail = false;

  useEffect(() => {
    async function fetchData() {
      setLoadAlerts(true);
      const currentUser = await getCurrentUser({ email: null }, "fromDB");
      setLoadAlerts(false);
      if (currentUser) {
        setCredential({
          ...credential,
          username: currentUser.username,
          email: currentUser.email,
          firstname: currentUser.first_name,
          lastname: currentUser.last_name,
          address: currentUser.address,
          street_address: currentUser.street_address,
          city: currentUser.city,
          state: currentUser.state,
          zipcode: currentUser.zipcode,
          selected_ffl_id: userData.selected_ffl_id
            ? userData.selected_ffl_id
            : "",
          savedCardNum: currentUser.creditCardNumber,
          is_Delete: isDelete,
        });
        setPhone(currentUser.phone_number);

        if (currentUser.notify_products === "none") isProd = false;
        else {
          isProd = true;
          if (currentUser.notify_products.includes("email")) isProdEmail = true;
          if (currentUser.notify_products.includes("phone")) isProdPhone = true;
        }

        if (currentUser.notify_webinar === "none") isWebinar = false;
        else {
          isWebinar = true;
          if (currentUser.notify_webinar.includes("email"))
            isWebinarEmail = true;
          if (currentUser.notify_webinar.includes("phone"))
            isWebinarPhone = true;
        }

        setNotification({ product: isProd, webinar: isWebinar });
        setProdNot({ phone: isProdPhone, email: isProdEmail });
        setWebinarNot({ phone: isWebinarPhone, email: isWebinarEmail });
      }
      if (userData.profile_picture)
        setProfilePicURL(getImageFromS3(userData.profile_picture));
    }
    if (userData) fetchData();
  }, []);

  // useEffect(() => {

  //     async function update() {
  //         await finalUpdate(false, "email", true);
  //     }
  //     currentUser && update();
  // }, [isEmailVerified]);

  useEffect(() => {
    async function update() {
      await finalUpdate(false, "phone", true);
    }
    currentUser && update();
  }, [isPhoneVerified]);

  useEffect(() => {
    if (phone?.trim() === "" || !validatePhoneNumber(phone?.slice(2))) {
      setIsValidPhone(true);
    } else {
      setIsValidPhone(false);
    }
  
    return () => {
      setIsValidPhone(false)
    }
  }, [phone])

  //For selecting verification methods
  const selectOptions = ["Reverify", "Change"];
  const [optionShow, setOptionShow] = useState(false);
  const [placeholder, setPlaceholder] =useState('Zip Code')

  // For upload image crop

  const [upImg, setUpImg] = useState();
  const [imgRef, setImgRef] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 9 / 9 });
  const [previewUrl, setPreviewUrl] = useState();
  const [isShow, setIsShow] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const uploadFile = useRef(null);
  const fileInputLink = React.createRef();

  // for phone validation
  const validatePhoneNumber = (num) => {
    var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return phoneNumberPattern.test(num);
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
    setIsShow(true);
  };

  const onLoad = (img) => {
    setImgRef(img);
  };

  const makeClientCrop = async (crop) => {
    if (imgRef && crop.width && crop.height) {
      createCropPreview(imgRef, crop, "profile.jpeg");
    }
  };

  const createCropPreview = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        let arr = reader.result.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        uploadFile.current = new File(
          [u8arr],
          "profile-" + userData.id + "-pic.jpg",
          { type: mime }
        );
      };
    });
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(previewUrl);

        setPreviewUrl(window.URL.createObjectURL(blob));
      }, "image/jpeg");
    });
  };

  const s3Upload = useCallback(
    async (file) => {
      const stored = await Storage.put(file.name, file, {
        contentType: file.type,
        cacheControl: "max-age=31536000",
      });
  
      return stored.key;
    },
    [],
  );

  // const emailVerifyFunc = async () => {

  //     try {
  //         const user = await Auth.currentAuthenticatedUser();

  //         await Auth.updateUserAttributes(user, { email: credential.email });

  //         if(isEmailVerified)
  //             setIsEmailVerified(false)
  //         else
  //             await finalUpdate(false, "email");

  //         setIsLoading(false);
  //         setCodeSent({ phone: false, email: true });

  //     } catch (error) {
  //         console.log(error);
  //         alert.error(error.message);
  //         setIsLoading(false);
  //     }
  // }

  const phoneVerifyFunc = async () => {
    setIsLoading(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { phone_number: phone });

      if (isPhoneVerified) setIsPhoneVerified(false);
      else await finalUpdate(false, "phone");

      setIsLoading(false);
      setCodeSent({ email: false, phone: true });
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };

  const finalUpdate = useCallback(
    async (
      verifiedVal,
      emailOrPhone,
      isPhoneChange = false
    ) => {
      if (isEmailVerified && isPhoneVerified) verifiedVal = true;
  
      try {
        const attachment = uploadFile.current
          ? await s3Upload(uploadFile.current)
          : userData.profile_picture;
  
        // for notification for prods and webinar
        let notify_product_val = "";
        let notify_webinar_val = "";
  
        if (notification.product) {
          if (prodNot.phone && prodNot.email)
            notify_product_val = "email and phone";
          else if (prodNot.phone && !prodNot.email) notify_product_val = "phone";
          else if (!prodNot.phone && prodNot.email) notify_product_val = "email";
          else notify_product_val = "none";
        } else notify_product_val = "none";
  
        if (notification.webinar) {
          if (webinarNot.phone && webinarNot.email)
            notify_webinar_val = "email and phone";
          else if (webinarNot.phone && !webinarNot.email)
            notify_webinar_val = "phone";
          else if (!webinarNot.phone && webinarNot.email)
            notify_webinar_val = "email";
          else notify_webinar_val = "none";
        } else notify_webinar_val = "none";
  
        const body = {
          id: userData.id,
          username: credential.username,
          first_name: credential.firstname,
          last_name: credential.lastname,
          address:
            credential.street_address +
            ", " +
            credential.city +
            ", " +
            credential.state +
            " " +
            credential.zipcode +
            ", USA",
          street_address: credential.street_address,
          city: credential.city,
          state: credential.state,
          zipcode: credential.zipcode,
          selected_ffl_id: credential.selected_ffl_id,
          profile_picture: attachment,
          verified_method: verifyMethod,
          is_verified: verifiedVal,
          is_email_verified: isEmailVerified,
          is_phone_verified: isPhoneVerified,
          isDelete: isDelete,
          phoneNumberModificaton: phoneNumberModificaton
        };
  
        if (emailOrPhone === "both") {
          body.email = credential.email;
          body.phone_number = phone;
        } else if (emailOrPhone === "email") body.email = credential.email;
        else body.phone_number = phone;
  
        if (!isPhoneChange) {
          body.notify_products = notify_product_val;
          body.notify_webinar = notify_webinar_val;
        }
  
        const result = await updateCurrentUser(body);
        return result;
        // const attachmentURL = await Storage.vault.get(attachment);
      } catch (e) {
        console.log(e);
      }
    },
    [credential, isDelete, isEmailVerified, isPhoneVerified, notification, phone, prodNot, s3Upload, updateCurrentUser, userData, verifyMethod, webinarNot],
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setChangePhone(false);
  
      if (isLoading || isValidPhone) return;
  
      if (uploadFile.current && uploadFile.current.size > 5000000) {
        alert.error("Please pick a file smaller than 5 MB.");
        return;
      }
  
      // if (credential.email !== userData.email) {
      //     emailVerifyFunc();
      // }
      // if (phone !== userData.phone_number) {
      //   phoneVerifyFunc();
      // } else {
      setIsLoading(true);
      const result = await finalUpdate(false, "both");
      setIsLoading(false);
      if(!searchButton){
        if(result === "success"){
          alert.success("Updated successfully");
          setClicList(!clicList);
          setClick(!click);
          setConfirmedFFL(true)
        } else{
          alert.success("Failed");
          setClicList(!clicList);
          setClick(!click);
          setConfirmedFFL(true)
        }
      }
      setSearchButton(false);
      // }
    },
    [alert, clicList, click, finalUpdate, isLoading, isValidPhone, searchButton],
  )
  ;

  const removeFFLOfUser = async () => {
    if(userData){
      setIsLoading(true);
      const result = await removeFFLFromUser({
        id: userData?.id,
      })
      setIsLoading(false);
      if(result === "success"){
        setIsLoading(true);
        const userFFLInfo = await getFFLScopeInfo(userData.id);
        setFFLScopeInfo(userFFLInfo.fflObj);
        setUserHasAlreadyFFL(false);
        setUserHasFFL(false)
        setIsLoading(false);
        setSelectedFFL(false)
        alert.success("Removed successfully");
      }  else{
        alert.error("Failed");
      }
    }
  }

  const handleConfirmSubmit = async (event) => {
    event.preventDefault();

    if (isLoading) return;

    setIsLoading(true);

    try {
      await Auth.verifyCurrentUserAttributeSubmit(
        "email",
        credential.confirmcode
      );
      setIsEmailVerified(true);
      setChangeEmail(false);
      if (phone !== userData.phone_number)
        phoneVerifyFunc(); ////////////////////////
      else setCodeSent({ phone: false, email: false });
      setCredential({ ...credential, isVerified: true });
      setIsLoading(false);
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };

  const handlePhoneConfirmSubmit = async (event) => {
    event.preventDefault();
    if (isLoading) return;

    setIsLoading(true);

    try {
      await Auth.verifyCurrentUserAttributeSubmit(
        "phone_number",
        credential.confirmcode
      );
      setIsPhoneVerified(true);
      setChangePhone(false);
      setCodeSent({ email: false, phone: false });
      setIsLoading(false);
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };

  const resendCode = async (phonenumber = false) => {
    if (isLoading) return;
    setIsResend(true);
    try {
      phonenumber
        ? await Auth.verifyCurrentUserAttribute("phone_number")
        : await Auth.verifyCurrentUserAttribute("email");
      setIsResend(false);
    } catch (error) {
      alert.error(error.message);
      setIsResend(false);
    }
  };

  const handleReverifiedSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (placeholder === "Reverify") {
      try {
        await Auth.verifyCurrentUserAttribute("email");

        setIsLoading(false);
        setCredential({ ...credential, isVerified: true });
        setCodeSent({ phone: false, email: true });
      } catch (error) {
        alert.error(error.message);
        setIsLoading(false);
      }
    } else {
      setChangeEmail(true);
      setIsLoading(false);
      setCredential({ ...credential, isVerified: true });
      setCodeSent({ phone: false, email: false });
    }
  };

  const handleReverifiedPhoneSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (placeholder === "Reverify") {
      try {
        await Auth.verifyCurrentUserAttribute("phone_number");

        setIsLoading(false);
        setCredential({ ...credential, isVerified: true });
        setCodeSent({ email: false, phone: true });
      } catch (error) {
        alert.error(error.message);
        setIsLoading(false);
      }
    } else {
      setChangePhone(true);
      setIsLoading(false);
      setCredential({ ...credential, isVerified: true });
      setCodeSent({ phone: false, email: false });
    }
  };

  const getAddressFunc = (place) => {
    const newArray = place.formatted_address.split(", ");
    let tempStreet = "";
    let tempCity = "";
    let tempState = "";
    let tempZip = "";
    if (newArray.length === 4) {
      tempStreet = newArray[newArray.length - 4];
      tempCity = newArray[newArray.length - 3];
      tempState = newArray[newArray.length - 2].split(" ")[0];
      tempZip = newArray[newArray.length - 2].split(" ")[1]
        ? newArray[newArray.length - 2].split(" ")[1]
        : "";
    } else {
      tempStreet = newArray[0];
    }
    setCredential({
      ...credential,
      street_address: tempStreet,
      city: tempCity,
      state: tempState,
      zipcode: tempZip,
    });
  };

  const renderLicenseFFLItem = useCallback(
    (item) => {
      const hasLoaExpirationDate = isValidLoaExpirationDate(item)
      let description = `Address: 
        ${item.premiseStreet} 
         - 
        ${item.premiseCity} 
         - 
        ${item.premiseZipCode}
        , State:  
        ${item.premiseState}`
      if (hasLoaExpirationDate) {
        description = `Loa Expiration Date: ${item.loaExpirationDate}, ${description}`
      }
      description = `Expiration Date: ${item.expiration}, ${description}`
      
      description = `${(item.businessName != null ? item.businessName : item.licenseName)}, ${description}`
      
      const className = item.fflNumber === fflScopeInfo?.fflNumber ? "leaderboard__name1" : "leaderboard__name"
      const itemId = item.fflNumber === fflScopeInfo?.fflNumber ? "selectFFL" : ""
      return (
        <article class="leaderboard__profile">
          <span
            class={className}
            id={itemId}
            key={item.fflNumber}
            onClick={() => {
              setSelectedFFL(true);
              setFFLScopeInfo(item);
              setClicList(item);
              setClick(true);
            }}
          >
            {description}
          </span>
        </article>
      );
    },
    [fflScopeInfo],
  )

  const renderForm = () => (
    <div className="profile-form">
      <Helmet>
        <title>Profile</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Profile Page" />
        <meta property="og:title" content="Profile Page" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="Mata's Tactical Supply" />
        <meta property="og:description" content="Profile Page" />
      </Helmet>

      {
        showConfirmationModal && (
            <div className="modal-profile-form">
            <div className="content">
              <div className="removeDialogWrapper">
                <div className="removeDialog">
                    <h2 className="text-white text-center ">
                      Are you sure you want to proceed?
                    </h2>
                    <p></p>
      
                    <MDBRow center className="mt-4">
                        <MDBCol size="8" sm="6" md="5" lg="6">
                            <FormButton
                              isLoading={isLoading}
                              onClickFunc={()=>{
                                removeFFLOfUser()
                                setShowConfirmationModal(false)
                              }
                            }>
                              <h5>Yes</h5>
                            </FormButton>
                        </MDBCol>
                        <MDBCol size="8" sm="6" md="5" lg="6">
                        <FormButton 
                            greyCol={true}
                            onClickFunc={()=>{
                                setShowConfirmationModal(false)
                            }}> 
                            <h5>No</h5>
                        </FormButton>
                        </MDBCol>
                    </MDBRow>
                  </div>
                  <br></br>
                </div>
              </div>
            </div>                  
        )
      }	

      <form onSubmit={handleSubmit}>
        <h2
          className="text-center font-weight-bold mb-4"
          style={{ color: fontColors.header1 }}
        >
          My Profile
        </h2>

        <div className="photo-div">
          {previewUrl && (
            <img
              className="photo-img"
              alt={credential.username}
              src={previewUrl}
            />
          )}
          {!previewUrl && profilePicURL && (
            <img
              className="photo-img"
              alt={credential.username}
              src={profilePicURL}
            />
          )}
          {!(previewUrl || profilePicURL) && (
            <div
              className="non-photo-img"
              onClick={() => fileInputLink.current.click()}
            >
              <MDBIcon icon="camera" />
            </div>
          )}
        </div>
        <div className="upload-text-wrapper">
          <span
            className="text-center mt-2 upload-text"
            onClick={() => fileInputLink.current.click()}
            style={{ color: fontColors.header2 }}
          >
            <MDBIcon icon="pencil-alt" className="mr-2" />
            Edit Picture
          </span>
        </div>

        <div>
          <input
            type="file"
            accept="image/*"
            id="hidden-input-field"
            onChange={onSelectFile}
            ref={fileInputLink}
          />
        </div>
        {isShow && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div id="crop-container" className="mt-2">
              <ReactCrop
                className="crop-div"
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={makeClientCrop}
              />
              <button id="crop-btn" onClick={() => setIsShow(false)}>
                Done
              </button>
            </div>
          </div>
        )}
       {/*  <FormInput
          type="text"
          name="username"
          label="Username*"
          value={credential.username}
          handleChange={(event) =>
            setCredential({ ...credential, username: event.target.value })
          }
          required
        /> */}
        <FormInput
          type="text"
          name="firstname"
          label="First Name*"
          value={credential.firstname}
          handleChange={(event) =>
            setCredential({ ...credential, firstname: event.target.value })
          }
          required
        />
        {!userHasAlreadyFFL && (
          <div className="removeDialogWrapper">
            <div className="removeDialog">
              <MDBIcon
                className="float-right text-white closeIcon"
                icon="times"
                onClick={() => {
                  setUserHasAlreadyFFL(true);
                }}
              />
              <div>
                <h2 className="text-white text-center ">
                  You have not selected any FFL!
                </h2>
              </div>
              <br></br>
            </div>
          </div>
        )}

        <FormInput
          type="text"
          name="lastname"
          label="Last Name*"
          value={credential.lastname}
          handleChange={(event) =>
            setCredential({ ...credential, lastname: event.target.value })
          }
          required
        />
        <div className="phone-group">
          <Autocomplete
            className="phone-input"
            placeholder=""
            value={credential.street_address}
            onChange={(e) =>
              setCredential({ ...credential, street_address: e.target.value })
            }
            onPlaceSelected={(place) => getAddressFunc(place)}
            types={["address"]}
            componentRestrictions={{ country: "us" }}
            style={{ color: fontColors.form }}
            required
          />
          <label
            className={`${
              credential.street_address ? "shrink" : ""
            } phone-input-label`}
          >
            Street Address*
          </label>
        </div>
        <FormInput
          type="text"
          name="city"
          label="City*"
          value={credential.city}
          handleChange={(event) =>
            setCredential({ ...credential, city: event.target.value })
          }
          required
        />
        <FormInput
          type="text"
          name="state"
          label="State*"
          value={credential.state}
          handleChange={(event) =>
            setCredential({ ...credential, state: event.target.value })
          }
          required
        />
        <FormInput
          type="text"
          name="zip"
          label="Zipcode*"
          value={credential.zipcode}
          handleChange={(event) =>
            setCredential({ ...credential, zipcode: event.target.value })
          }
          required
        />
        <FormInput
          type="email"
          name="email"
          label="Email*"
          value={credential.email}
          handleChange={(event) =>
            setCredential({ ...credential, email: event.target.value })
          }
          required
        />
        {/* {
                    changeEmail && <p className="text-danger changeP">Please change current email.</p>
                } */}
        <div className="phone-group">
          <Input
            className={`${
              changePhone || isValidPhone ? "red-outline" : ""
            } phone-input`}
            country="US"
            value={phone}
            onChange={(val)=>{
              setPhone(val)
              setPhoneNumberModificaton(true)
            }}
            autoFocus={changePhone}
            style={{ color: fontColors.form }}
          />
          <label
            className={`${phone ? "shrink" : ""} ${
              changePhone || isValidPhone ? "red-label" : ""
            } phone-input-label`}
          >
            Phone Number*
          </label>
          {changePhone && (
            <p className="text-danger changeP">
              Please change current phone number.
            </p>
          )}
          {isValidPhone && (
            <p className="text-danger changeP">Invalid phone number.</p>
          )}
        </div>
        <p
          className="font-weight-bold mb-4"
          style={{ color: fontColors.header1, fontSize: "18px" }}
        >
          FFL INFORMATION 
        </p>

        {
          (click && !userHasAFFL) ||   (click && isFFLexpired)? (
            <MDBCol sm="12" md="12" lg="4" xl="10" className="mb-4">
            <span style={{ color:'yellow' }}>
              FFL not saved. Please save your selection.
              </span>            
          </MDBCol>
          ):  (!click && isFFLexpired)? (
            <MDBCol sm="12" md="12" lg="4" xl="10" className="mb-4">
            <span style={{ color:'#d90b2a' }}>
              FFL expired. Please select a valid FFL.
              </span>            
          </MDBCol>
          ) :
          ""
        }
 
        {selectedFFL ? (
          <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">           
            {fflScopeInfo?.businessName != null &&
              fflScopeInfo?.businessName != "" && (
                <MDBRow>
                  <MDBCol sm="12" md="12" lg="4" xl="4" className="mb-4">
                    <span
                      style={{ color: fontColors.paragraph, fontSize: "16px" }}
                    >
                      Selected FFL:{" "}
                    </span>
                  </MDBCol>
                  <MDBCol sm="12" md="12" lg="6" xl="6" className="mb-4">
                    <p
                      style={{ color: fontColors.header1, fontSize: "20px" }}
                      className="mb-1 pb-0"
                    >
                      {fflScopeInfo?.businessName}
                    </p>
                  </MDBCol>
                </MDBRow>
              )}
            <MDBRow>

              <MDBCol sm="12" md="12" lg="4" xl="4" className="mb-4">
                <span style={{ color: fontColors.paragraph, fontSize: "16px" }}>
                  License Name:{" "}
                </span> 
                
              </MDBCol>
              <MDBCol sm="12" md="12" lg="6" xl="6" className="mb-4">
                <p
                  style={{ color: fontColors.header1, fontSize: "20px" }}
                  className="mb-1 pb-0"
                >
                  {fflScopeInfo?.licenseName}
                </p>

              </MDBCol>
              {
                  (click && !userHasAFFL) ||   (click && isFFLexpired) ? (
                  
                  <MDBCol sm="2" md="" lg="2" xl="2" className="mb-4">
                    <MDBIcon icon="exclamation-triangle"  style={{color: "yellow", justifyContent:'center', textAlign:"center" ,fontSize: '30px'}} />
                  </MDBCol>
                ): 
                click && userHasAFFL && startEmpyFFL && timer  && !isFFLexpired ? (
             
                  <MDBCol sm="2" md="" lg="2" xl="2" className="mb-4">
                  <MDBIcon icon="check-circle"  style={{color: "green", justifyContent:'center', textAlign:"center" ,fontSize: '35px'}} />
                </MDBCol> 
                ) : (!click && isFFLexpired) ? (
                  <MDBCol sm="2" md="" lg="2" xl="2" className="mb-4">
                  <MDBIcon icon="ban"  style={{color: "#d90b2a", justifyContent:'center', textAlign:"center" ,fontSize: '30px'}} />
                </MDBCol>
                )
                
                : ''
              }
           
            </MDBRow>

            { hasLoaExpirationDate && 
              <MDBRow>
                <MDBCol sm="6" md="6" lg="4" xl="4" className="mb-4">
                  <span style={{ color: fontColors.paragraph, fontSize: "16px" }}>
                    Loa Expiration Date:{" "}
                  </span>
                </MDBCol>
                {(!click && isFFLexpired) ? (

                  <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">            
                  <p
                    style={{ color: "#d90b2a", fontSize: "18px" }}
                    className="mb-1 pb-0"
                  >               
                  {
                    fflScopeInfo?.loaExpirationDate != null && fflScopeInfo?.loaExpirationDate !== "" &&
                    moment(fflScopeInfo?.loaExpirationDate.substring(0,10)).format('MM/DD/YYYY')
                  }

                  </p>
                  </MDBCol>
                )
                :
                (
                  <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">            
                  <p
                    style={{ color: fontColors.header1, fontSize: "18px" }}
                    className="mb-1 pb-0"
                  >               
                  {moment(fflScopeInfo?.loaExpirationDate?.substring(0,10)).format('MM/DD/YYYY')}
                  
                  </p>
                </MDBCol>
                )
                }
              </MDBRow>
            }

            <MDBRow>
              <MDBCol sm="6" md="6" lg="4" xl="4" className="mb-4">
                <span style={{ color: fontColors.paragraph, fontSize: "16px" }}>
                  Expiration Date:{" "}
                </span>
              </MDBCol>
              {(!click && isFFLexpired) ? (

                <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">            
                <p
                  style={{ color: "#d90b2a", fontSize: "18px" }}
                  className="mb-1 pb-0"
                >               
                {
                  fflScopeInfo?.expiration != null && fflScopeInfo?.expiration !== "" &&
                  moment(fflScopeInfo?.expiration.substring(0,10)).format('MM/DD/YYYY')
                }

                </p>
                </MDBCol>
              )
              :
              (
                <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">            
                <p
                  style={{ color: fontColors.header1, fontSize: "18px" }}
                  className="mb-1 pb-0"
                >               
                 {moment(fflScopeInfo?.expiration?.substring(0,10)).format('MM/DD/YYYY')}
                
                </p>
              </MDBCol>
              )
              }
           
            </MDBRow>
            <MDBRow>
              <MDBCol sm="6" md="6" lg="4" xl="4" className="mb-4">
                <span style={{ color: fontColors.paragraph, fontSize: "16px" }}>
                  Address:{" "}
                </span>
              </MDBCol>
              <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">
                <p
                  style={{ color: fontColors.header1, fontSize: "18px" }}
                  className="mb-1 pb-0"
                >
                  {fflScopeInfo?.premiseStreet}, {fflScopeInfo?.premiseCity},{fflScopeInfo.premiseState != null ? fflScopeInfo.premiseState + ", " : ' '}
                  {fflScopeInfo?.premiseZipCode}
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol sm="6" md="6" lg="4" xl="4" className="mb-4">
                <span style={{ color: fontColors.paragraph, fontSize: "16px" }}>
                  Contact Number:{" "}
                </span>
              </MDBCol>
              <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">
                <p
                  style={{ color: fontColors.header1, fontSize: "18px" }}
                  className="mb-1 pb-0"
                >
                  {fflScopeInfo?.voiceTelephone == null
                    ? "N/A"
                    : "" }
                    
                    ({fflScopeInfo?.voiceTelephone.slice(0, 3)}) {fflScopeInfo?.voiceTelephone.slice(3, 6)}-{fflScopeInfo?.voiceTelephone.slice(6)}
                </p>
              </MDBCol>
            </MDBRow>
            {(!confirmedFFL   || clicList || click) && (
              <MDBCol size="6" sm="12" md="12" lg="12" xl="12" className="mb-4">
                <FormButton isLoading={isLoading} onClickFunc={() => saveFFL()}>Save FFL</FormButton>
              </MDBCol>
            )}

            {
              userHasAFFL && userHasAlreadyFFL && (
                <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-6">
                  <FormButton
                    greyCol={true}
                    type="button"
                    isLoading={isLoading} 
                    onClickFunc={() => setShowConfirmationModal(true)}>
                      Remove FFL
                  </FormButton>
                </MDBCol>
              )
            }

          </MDBCol>
        ) : (
          <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-6">
            <MDBRow>
              <MDBCol
                size="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="mb-6"
              >
                <h2
                  style={{
                    color: "white",
                    justifyContent: "center",
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  You have not selected any FFL!{" "}
                </h2>
                <h2
                  style={{
                    color: "white",
                    justifyContent: "center",
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  Use the map to search and pick one
                </h2>
                <br></br>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        )}
        {isOnSearchModeFFL ? (
          <MDBCol size="12" sm="12" md="12" lg="6" xl="12" className="mb-4">
            <MDBRow className="">
            <MDBCol size="12" sm="12" md="12" lg="6" xl="12">
              <MDBRow>
              <p
                className="font-weight-bold"
                style={{
                    color: fontColors.paragraph,
                    fontSize: '16px',
                    margin: 0,
                  }}
                >
                Search type
                </p>
              </MDBRow>
            </MDBCol>
            <MDBCol size="12" sm="12" md="12" lg="6" xl="6" >
              <MDBRow 
               //  className='form-select example'
              // style={{margin:'30px -10px 2px 0px ' , width:'200%'}}
              >
                <FormSelect
                width200={true}
                  options={['ZIPCode', 'Name', 'Number']}

                  selectOption={event => {
                    let temp = event.target.id
                    setSearchType(temp?.toLowerCase())
                    setOptionShow(false)
                    setPlaceholder(event.target.id)
                    setEmptyFields(false)
                    setSearchName('')
                    setSearchNumber('')
                    setSearchZipCode('')
                    setSearchRadius('')
                  }}
                  showSelectBox={() => setOptionShow(!optionShow)}
                  optionShow={optionShow}
                  placeholder={placeholder}                     
                />
              
                </MDBRow>
            </MDBCol>
            </MDBRow>                
                {searchType === 'name' && (
               <MDBCol size="12" sm="12" md="12" lg="6" xl="6">
                    <MDBRow>
                      <div
                        className="font-weight-bold mb-2"
                        style={{
                          color: fontColors.paragraph,
                          fontSize: '16px',
                          margin: 0,
                        }}
                      >
                        FFL Name
                      </div>
                    </MDBRow>
                    <MDBRow>
                      <FormInput
                        type="text"
                        style={{ margin: 0 }}
                        value={searchName}
                        handleChange={event =>
                          handleFFLNameChange(event.target.value)
                        }
                        placeholder="Enter the name of the FFL"
                      />
                    </MDBRow>
                  </MDBCol>
                )}
                {searchType === 'number' && (
                  <MDBCol
                    size="12"
                    sm="12"
                    md="12"
                    className="modal-input-item"
                  >
                    <MDBRow>
                      <p
                        className="font-weight-bold mb-2"
                        style={{
                          color: fontColors.paragraph,
                          fontSize: '16px',
                          margin: 0,
                        }}
                      >
                        FFL Number
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <FormInput
                        type="text"
                        style={{ margin: 0 }}
                        value={searchNumber}
                        handleChange={(event) =>
                          handleFFLNumberChange(event.target.value)
                        }
                        placeholder="Enter the number of the FFL"
                      />
                    </MDBRow>
                  </MDBCol>
                )}
                {(searchType != 'name' && searchType != 'number' ) && (                 
                  <MDBRow className="">
                  <MDBCol size="12" sm="12" md="12" lg="6" xl="6">
                    <MDBRow>
                      <p
                        className="font-weight-bold mb-2"
                        style={{
                          color: fontColors.paragraph,
                          fontSize: "16px",
                          margin: 0,
                        }}
                      >
                        Search by ZIP Code
                      </p>
                      <FormInput
                        type="text"
                        className={`form-input ${!click === true && isFFLexpired === true && 'red-outline'}`}
                        style={{ margin: 0 }}
                        value={searchZipCode}
                        placeHolder="Enter your zip code"
                        handleChange={(event) =>
                          handleZIPCodeChange(event.target.value)
                        }
                      />
                    </MDBRow>
                  </MDBCol>
                  <MDBCol size="12" sm="12" md="12" lg="6" xl="6">
                    <MDBRow>
                      <p
                        className="font-weight-bold mb-2"
                        style={{
                          color: fontColors.paragraph,
                          fontSize: "16px",
                          margin: 0,
                        }}
                      >
                        Radius: (default 10 miles)
                      </p>
                      
                      <FormInput
                        type="text"
                        className={`form-input ${!click === true && isFFLexpired === true && 'red-outline'}`}
                        style={{ margin: 0 }}
                        value={searchRadius}
                        handleChange={(event) =>
                          handleRadiusCodeChange(event.target.value)
                        }
                        placeHolder="Enter the radius to look around"
                      />
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                )}
      
            <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
              <FormButton
                /* isDisabled={! (searchZipCode.length>3)} */
                isLoading={loadButton}
                onClickFunc={() => searchNearbyFFL()}
              >
                Search
              </FormButton>
            </MDBCol>
          </MDBCol>
        ) : (
          <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
            <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
              <FormButton
                onClickFunc={() => {
                  setIsOnSearchModeFFL(true);
                  setSelectedFFL(false);
                  setIsvisibleTable(true);
                }}
              >
                Search again?
              </FormButton>
            </MDBCol>
          </MDBCol>
        )}       
        {isvisibleTable && licenseffl?.data?.length > 0 ?  (
          <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
            <a href="#selectFFL" id="ghost" hidden>
              1
            </a>

            <main class="leaderboard__profiles">
              {        
                licenseffl?.data?.map((item) => renderLicenseFFLItem(item))
              }
             
            </main>
          </MDBCol>
        ) : 
        ( errorLength &&
            (
              <div class="error-message">
                <h3>No results found</h3>
              </div>
            )
        )}

        {
          <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
            {isLoadingMap ? (
              <div className="wait-loading">
                <MDBIcon className="loadIcon" icon="sync-alt" />
              </div>
            ) : (
              <div style={{ display: "flex", height: "100%" }}>
                <Wrapper apiKey={""} render={render}>
                  <Map
                    locations={locations}
                    view={view}
                    setFFLScopeInfo={setFFLScopeInfo}
                    setSelectedFFL={setSelectedFFL}
                    clicList={clicList}
                    fflScopeInfo={fflScopeInfo}
                    setClick ={setClick}
                  ></Map>
                </Wrapper>
              </div>
            )}
          </MDBCol>
        }
        
     {/*    {
          clicList  &&
           <MDBCol size="6" sm="12" md="12" lg="12" xl="12" className="mb-4">
            <FormButton onClickFunc={() => saveFFL()}>Save FFL</FormButton>
          </MDBCol> 
        } */}

        {/* <FormInput type="text" name='selected_ffl_id' label='FFL*' value={credential.selected_ffl_id} handleChange={(event) => setCredential({ ...credential, selected_ffl_id: event.target.value })} required />                 */}
        <NotificationSelect
          title="Notifications for new products"
          notif={notification?.product}
          isSMS={false}
          handleChange={() => {
            setNotification({
              ...notification,
              product: !notification?.product,
            });
            setProdNot({ phone: false, email: false });
          }}
          emailNotif={prodNot.email}
          emailNotifChange={() =>
            setProdNot({ ...prodNot, email: !prodNot?.email })
          }
          phoneNotif={prodNot.phone}
          phoneNotifChange={() =>
            setProdNot({ ...prodNot, phone: !prodNot?.phone })
          }
        />
        <NotificationSelect
          title="Get notified when your purchased video webinar goes live"
          isSMS={true}
          notif={notification.webinar}
          handleChange={() => {
            setNotification({
              ...notification,
              webinar: !notification?.webinar,
            });
            setWebinarNot({ phone: false, email: false });
          }}
          emailNotif={webinarNot.email}
          emailNotifChange={() =>
            setWebinarNot({ ...webinarNot, email: !webinarNot?.email })
          }
          phoneNotif={webinarNot?.phone}
          phoneNotifChange={() =>
            setWebinarNot({ ...webinarNot, phone: !webinarNot?.phone })
          }
        />

        <FormButton
          type="submit"
          isLoading={isLoading}
          setIsvisibleTable={false}
          // userHasAFFL={true}
          onClickFunc={() => saveFFL()}
        >
          SAVE NOW
        </FormButton>
      </form>
    </div>
  );

  const renderVerificationForm = (phonenumber = false) => (
    <div className="profile-form confirm">
      <VerifyCodeInput
        handleConfirmSubmit={
          phonenumber ? handlePhoneConfirmSubmit : handleConfirmSubmit
        }
        email_or_phone={phonenumber ? "Phone" : "Email"}
        phone={phone}
        credential={credential}
        setCredential={setCredential}
        resendCode={() => resendCode(phonenumber)}
        isResend={isResend}
        isShowResend={true}
        isLoading={isLoading}
      />
    </div>
  );

  const renderReverifyForm = () => (
    <div className="profile-form unverified-form">
      <form
        onSubmit={
          !isPhoneVerified
            ? handleReverifiedPhoneSubmit
            : handleReverifiedSubmit
        }
      >
        <div className="icon-div">
          <MDBIcon far icon="times-circle" className="unverified-icon" />
        </div>
        <h2 className="text-center" style={{ color: fontColors.header1 }}>
          You are not verified!
        </h2>
        <p
          className="text-center font-weight-bolder"
          style={{ color: fontColors.paragraph }}
        >
          You should reverify or change your{" "}
          {!isPhoneVerified ? "Phone" : "Email"}{" "}
          <span>({!isPhoneVerified ? phone : credential?.email})</span> again.
        </p>
        <FormSelect
          options={selectOptions}
          label={
            placeholder === "Reverify"
              ? !isPhoneVerified
                ? "Reverify your phone"
                : "Reverify your email"
              : !isPhoneVerified
              ? "Change your phone"
              : "Change your email"
          }
          showSelectBox={() => setOptionShow(!optionShow)}
          selectOption={(event) => {
            setOptionShow(false);
            setPlaceholder(event.target.id);
          }}
          optionShow={optionShow}
          placeholder={placeholder}
        />
        <FormButton type="submit" isLoading={isLoading}>
          NEXT
        </FormButton>
      </form>
    </div>
  );

  const deleteFunc = () => {
    setIsDelete(true);
    credential.savedCardNum = "";
  };

  return (
    <div className="myaccount-page">
      {codeSent.email && renderVerificationForm()}
      {codeSent.phone && renderVerificationForm(true)}
      {!credential.isVerified && renderReverifyForm()}
      {!(codeSent.email || codeSent.phone || !credential.isVerified) &&
        renderForm()}

      {/* COMMENTED FFL SECTION */}
    </div>
  );
};

const MapStateToProps = ({
  colors: { currentFontColors },
  user: { currentUser },
}) => ({
  currentFontColors,
  currentUser,
});

const MapDispatchToProps = (dispatch) => ({
  updateCurrentUser: updateCurrentUser(dispatch),
  getCurrentUser: getCurrentUser(dispatch),
  setLoadAlerts: (flag) => dispatch(setLoadAlerts(flag)), // for waiting load
  getFFLScopeInfo: getFFLScopeInfo(dispatch),
  saveFFLInformation: saveFFLInformation(dispatch),
  getFFLLicences: getFFLLicences(dispatch),
  getFFLLicencesFiltered :getFFLLicencesFiltered(dispatch),
  removeFFLFromUser: removeFFLFromUser(dispatch),
});
export default connect(MapStateToProps, MapDispatchToProps)(MyAccountPage);
