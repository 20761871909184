import React, { useMemo, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment-timezone';
import { useAlert } from "react-alert";
import { userHasFflSelected, getNewerFfl, saveFFLInformation } from "../../redux/ffl-db/ffl-db.action";
import { getCurrentUser } from "../../redux/user/user.action";

import { MDBCol, MDBRow, MDBModal, MDBModalBody, MDBModalFooter } from "mdbreact";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import FormButton from "../form-button/form-button.component";

import './ffl-verify-wrapper.style.scss'
import LoaderComponent from "../Loader/loader.component";
import { isValidLoaExpirationDate } from "../../utils/fflUtils";

const FflVerifyWrapper = ({
  children,
}) => {
  const historyUrl = useHistory()
  const alert = useAlert()
  const dispatch = useDispatch()
  const currentFontColors = useSelector((state) => state.colors.currentFontColors)

  const [fontColors, setFontColors] = useState({
    header1: "white",
    header2: "white",
    paragraph: "#a3a3a3",
    form: "#a3a3a3",
  });

  const [newestFfl, setNewestFfl] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openRedirectProfileModal, setOpenRedirectProfileModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const hasLoaExpirationDate = useMemo(() => isValidLoaExpirationDate(newestFfl), [newestFfl])
  const expirationDate = useMemo(() => hasLoaExpirationDate ? newestFfl?.loaExpirationDate : newestFfl?.expiration, [hasLoaExpirationDate, newestFfl])

  const saveFFL = useCallback(
    async () => {
      setOpenConfirmModal(false)
      const userId = JSON.parse(localStorage.getItem("userData"));
      setLoading(true);
      const result = await saveFFLInformation(dispatch)(newestFfl, userId?.id);
      setLoading(false);
      if (result.data === "success") {
        alert.success("FFL information saved!");
        setLoading(true);
        const userResult = await getCurrentUser(dispatch)(null, "fromDB");
        setLoading(false);
        const updateLocalStorageUserData = {
          ...userResult,
          ffl_scope_id: userResult?.ffl_scope_id,
        };
        if(userResult){
          localStorage.setItem("userData", JSON.stringify(updateLocalStorageUserData));
        }
      }
    },
    [alert, dispatch, newestFfl],
  );

  const cancelFFL = useCallback(
    async () => {
      setOpenConfirmModal(false)
      setOpenRedirectProfileModal(true)
    },
    [],
  );

  const redirectToProfile = useCallback(
    async () => {
      setOpenRedirectProfileModal(false)
      historyUrl.push("/myaccount/my_profile");
    },
    [historyUrl],
  );

  const handleExpiredFfl = useCallback(
    async (userId) => {
      setLoading(true);
      const newerFfl = await getNewerFfl(dispatch)(userId?.id);
      if (newerFfl.success) {
        setNewestFfl(newerFfl.result)
        setOpenConfirmModal(true)
      } else {
        cancelFFL()
      }
      setLoading(false);
    },
    [dispatch, cancelFFL],
  );

  const wrapperOnClick = useCallback(
    async () => {
      const userId = JSON.parse(localStorage.getItem("userData"))
      setLoading(true);
        const userHasSelect = await userHasFflSelected(dispatch)(userId?.id)
        setLoading(false);
        let { result, message, expired } = userHasSelect;
        if(userHasSelect && result){
          if (expired) {
           handleExpiredFfl(userId);
          }
        } else {
          alert.show(message || "You must select a valid FFL.",{
            timeout: 3000,
            type: 'error',
            onClose: () => {
              historyUrl.push("/myaccount/my_profile");
            }
          });
        }
    },
    [alert, dispatch, handleExpiredFfl, historyUrl],
  );

  useEffect(() => {
    wrapperOnClick()
  }, [wrapperOnClick])
  
  useEffect(() => {
    if (
      currentFontColors &&
      currentFontColors.header1_color &&
      currentFontColors.header2_color &&
      currentFontColors.paragraph_color &&
      currentFontColors.form_color
    ) {
      const header1color = JSON.parse(currentFontColors.header1_color);
      const header2color = JSON.parse(currentFontColors.header2_color);
      const paragraphcolor = JSON.parse(currentFontColors.paragraph_color);
      const formcolor = JSON.parse(currentFontColors.form_color);
      setFontColors({
        header1: `rgba(${header1color.r}, ${header1color.g}, ${header1color.b}, ${header1color.a})`,
        header2: `rgba(${header2color.r}, ${header2color.g}, ${header2color.b}, ${header2color.a})`,
        paragraph: `rgba(${paragraphcolor.r}, ${paragraphcolor.g}, ${paragraphcolor.b}, ${paragraphcolor.a})`,
        form: `rgba(${formcolor.r}, ${formcolor.g}, ${formcolor.b}, ${formcolor.a})`,
      });
    }
  }, [currentFontColors]);

  const newestFflBody = useMemo(() => 
    <div>
      <h4 className="text-white text-center">Do you want to use the following FFL?</h4>
      <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="my-4">
        <MDBRow>
          <MDBCol sm="12" md="12" lg="4" xl="4" className="mb-4">
            <span
              style={{ color: fontColors.paragraph, fontSize: "16px" }}
            >
              FFL:{" "}
            </span>
          </MDBCol>
          <MDBCol sm="12" md="12" lg="6" xl="6" className="mb-4">
            <p
              style={{ color: fontColors.header1, fontSize: "20px" }}
              className="mb-1 pb-0"
            >
              {newestFfl?.businessName}
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" md="12" lg="4" xl="4" className="mb-4">
            <span style={{ color: fontColors.paragraph, fontSize: "16px" }}>
              License Name:{" "}
            </span> 
            
          </MDBCol>
          <MDBCol sm="12" md="12" lg="6" xl="6" className="mb-4">
            <p
              style={{ color: fontColors.header1, fontSize: "20px" }}
              className="mb-1 pb-0"
            >
              {newestFfl?.licenseName}
            </p>

          </MDBCol>
        </MDBRow>
        { hasLoaExpirationDate && 
          <MDBRow>
            <MDBCol sm="6" md="6" lg="4" xl="4" className="mb-4">
              <span style={{ color: fontColors.paragraph, fontSize: "16px" }}>
                Loa Expiration Date:{" "}
              </span>
            </MDBCol>
            {(
              <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">            
                <p
                  style={{ color: fontColors.header1, fontSize: "18px" }}
                  className="mb-1 pb-0"
                >               
                {moment(newestFfl.loaExpirationDate?.substring(0,10)).format('MM/DD/YYYY')}
                
                </p>
              </MDBCol>
            )}
          </MDBRow>
        }
        <MDBRow>
          <MDBCol sm="6" md="6" lg="4" xl="4" className="mb-4">
            <span style={{ color: fontColors.paragraph, fontSize: "16px" }}>
              Expiration Date:{" "}
            </span>
          </MDBCol>
          {(
            <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">            
              <p
                style={{ color: fontColors.header1, fontSize: "18px" }}
                className="mb-1 pb-0"
              >               
              {moment(newestFfl?.expiration?.substring(0,10)).format('MM/DD/YYYY')}
              
              </p>
            </MDBCol>
          )}
        </MDBRow>
        <MDBRow>
          <MDBCol sm="6" md="6" lg="4" xl="4" className="mb-4">
            <span style={{ color: fontColors.paragraph, fontSize: "16px" }}>
              Address:{" "}
            </span>
          </MDBCol>
          <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">
            <p
              style={{ color: fontColors.header1, fontSize: "18px" }}
              className="mb-1 pb-0"
            >
              {newestFfl?.premiseStreet}, {newestFfl?.premiseCity},{newestFfl?.premiseState != null ? newestFfl.premiseState + ", " : ' '}
              {newestFfl?.premiseZipCode}
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="6" md="6" lg="4" xl="4" className="mb-4">
            <span style={{ color: fontColors.paragraph, fontSize: "16px" }}>
              Contact Number:{" "}
            </span>
          </MDBCol>
          <MDBCol sm="6" md="6" lg="6" xl="6" className="mb-4">
            <p
              style={{ color: fontColors.header1, fontSize: "18px" }}
              className="mb-1 pb-0"
            >
              {newestFfl?.voiceTelephone == null
                ? "N/A"
                : "" }
                
                ({newestFfl?.voiceTelephone.slice(0, 3)}) {newestFfl?.voiceTelephone.slice(3, 6)}-{newestFfl?.voiceTelephone.slice(6)}
            </p>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </div>,
  [fontColors, newestFfl, expirationDate])

  return (
    <div>
      {children}
      {loading && <LoaderComponent />}
      {openConfirmModal && (
        <ConfirmationModal
          closeModal={cancelFFL}
          isLoading={false}
          title={`Your FFL has expired`}
          body={newestFflBody}
          yesFunction={saveFFL}
        />
      )}
      <MDBModal isOpen={openRedirectProfileModal} centered closeOnEsc={false}>
        <MDBModalBody>
          <MDBRow className='mb-4 mt-4 text-center'>
            <p style={{ color: fontColors.header1, fontSize: "20px" }}>
              The FFL is expired and a valid FFL needs to be selected from the
              "My Profile" page to continue with the purchase process.
            </p>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <FormButton type='button' onClickFunc={redirectToProfile}>
            OK
          </FormButton>
        </MDBModalFooter>
      </MDBModal>
    </div>
  );
};

export default FflVerifyWrapper;
