import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import './product-page.style.scss';
import {MDBRow, MDBCol, MDBIcon} from 'mdbreact';
import {connect, useDispatch} from 'react-redux';
import FormSelect from '../../components/form-select/form-select.component';
// for waiting load
import {setLoadAlerts} from '../../redux/alerts/alerts.action';
import {getProducts, addMoreProducts, setOffset, addProducts} from '../../redux/products/products.action';
import {getWebinarPopUp} from '../../redux/others/others.action';
import ProductGalleryItem from '../../components/product-gallery-item/product-gallery-item.component';
import {withRouter} from 'react-router';
import InfiniteScroll from "react-infinite-scroll-component";
import {Helmet} from 'react-helmet';
import {useAlert, positions} from 'react-alert';
import { getImageFromS3 } from '../../utils/services/s3';

const ProductPage = withRouter(({
                                    location,
                                    isLoadingAlerts,
                                    getProducts,
                                    addMoreProducts,
                                    getWebinarPopUp,
                                    items,
                                    limit,
                                    offset,
                                    setOffset,
                                    setLoadAlerts,
                                    hasScroll
                                }) => {

    const alert = useAlert();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const historyUrl = useHistory();
    const dispatch = useDispatch();

    const [isIconSpin, setIsIconSpin] = useState(false);

    const [movedAfterCotinueShipping, setMovedAfterCotinueShipping] = useState(0);

    const [webinarPopupInfo, setWebinarPopupInfo] = useState({
        content: ""
    });

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
          setScrollPosition(window.pageYOffset);
        };
    
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    useEffect(() => {
    if(historyUrl && historyUrl?.location?.state){
        if(historyUrl?.location?.state?.hasScroll){
            setTimeout(() => {
                console.log("scrollPosition", parseInt(historyUrl.location.state.hasScroll));
                let aboveInitial = parseInt(historyUrl.location.state.hasScroll)
                if(aboveInitial > 270){
                    window.scrollTo({
                        top: aboveInitial,
                        behavior: 'smooth'
                    })
                }
            }, 1300);
        }
    }
    }, [historyUrl])

    const loadMoreData = async () => {
        if (items.count < (offset + limit))
            return;

        setIsIconSpin(true);
        await addMoreProducts(limit, offset + limit, 'webinar');
        // if (location?.state?.prodType) {
        //     await addMoreProducts(limit, offset + limit, location.state.prodType);
        // } else {
        //     if (JSON.parse(localStorage.getItem('prodType')))
        //     await addMoreProducts(limit, offset + limit, JSON.parse(localStorage.getItem('prodType')).prodType);
        setOffset(offset + limit);
        setIsIconSpin(false);
        console.log("scrollPosition", movedAfterCotinueShipping);
        let aboveInitial = parseInt(historyUrl?.location?.state?.hasScroll)
        if(aboveInitial > 250 && movedAfterCotinueShipping !== 1){
            window.scrollTo({
                top: aboveInitial,
                behavior: 'smooth'
            })
            if(window.scrollY === aboveInitial){
                setMovedAfterCotinueShipping(1)
                console.log("scrollPosition", movedAfterCotinueShipping);
            }
                
        }
    }

    useEffect(() => {
        async function loadData(type = 'webinar') {
            setLoadAlerts(true);
            setOffset(0);
            await getProducts(limit, 0, type);
            setLoadAlerts(false);
        }

        if (location?.state?.prodType)
            loadData(location.state.prodType);
        else if (JSON.parse(localStorage.getItem('prodType')))
            loadData(JSON.parse(localStorage.getItem('prodType')));
        else
            loadData();

        async function loadWebinarPopup() {
            setLoadAlerts(true);
            const result = await getWebinarPopUp();
            console.log("result", result);
            setLoadAlerts(false);
            if (result) {
                setWebinarPopupInfo({
                    content: result.webinarPopUpInfo
                })
            }
        }

        loadWebinarPopup();
    }, [location]);    

    const selectProdTypeHandler = (event) => {
        historyUrl.push(`/product/${event}`);
    }

    return (
        <div className="product-page">
            <Helmet>
                {/* <!-- HTML Meta Tags --> */}
                <title>Products</title>
                <link rel="canonical" href={window.location.href}/>
                <meta
                    name="description"
                    content="Products of Mata's Tactical Supply"
                />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content="Products"/>
                <meta
                    itemprop="description"
                    content="Products of Mata's Tactical Supply"
                />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Products"/>
                <meta
                    property="og:description"
                    content="Products of Mata's Tactical Supply"
                />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:title" content="Products"/>
                <meta
                    name="twitter:description"
                    content="Products of Mata's Tactical Supply"
                />
            </Helmet>

            {
                userData && location && location.state && location.state.firstName &&
                <div className="welcome-msg">Welcome,&nbsp;{userData.first_name}!</div>

            }
            {
                location?.state?.prodType==='physical'?
                <div className="container">
                    <MDBRow className="pt-12">
                        <MDBCol size="12" sm="6" md="6" lg="6" xl="6" className="list-item">
                            <img className='img-wrapper image-redirect' src="../../../images/video_back_small.jpg" onClick={()=>selectProdTypeHandler("matas")}/>
                        </MDBCol>
                        <MDBCol size="12" sm="6" md="6" lg="6" xl="6" className="list-item">
                            <img className='img-wrapper image-redirect' src="../../../images/firearms.jpg" onClick={()=>selectProdTypeHandler("firearms")}/>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="">
                        <MDBCol size="12" sm="6" md="6" lg="6" xl="6" className="list-item">
                            <img className='img-wrapper image-redirect' src="../../../images/accessories.jpg" onClick={()=>selectProdTypeHandler("accessories")}/>
                        </MDBCol>
                        <MDBCol size="12" sm="6" md="6" lg="6" xl="6" className="list-item">
                            <img className='img-wrapper image-redirect' src="../../../images/scopes.jpg" onClick={()=>selectProdTypeHandler("optics")}/>
                        </MDBCol>    
                    </MDBRow>
                </div>
                :
                <div>
                    {                                                   
                        items?.count === 0 ?
                            <p className="noProducts">{webinarPopupInfo.content}</p>
                            :
                            items?.data &&
                            <InfiniteScroll
                                className="own-infinite mt-3"
                                dataLength={items.data.length}
                                scrollThreshold={Math.min(0.8 * window.devicePixelRatio / 1.875, 0.8)} // move threshhold based on scale but not exceeding 0.8
                                next={loadMoreData}
                                hasMore={items.count > (offset + limit)}
                                loader={isIconSpin && <div className="load-icon-wrapper">
                                    <MDBIcon className={`load-more load-icon ${isIconSpin && 'spin'} `} icon="sync-alt"/>
                                </div>}
                            >
                                <MDBRow>
                                    {
                                        !isLoadingAlerts && items && items.data && items.data.map((item, i) => (
                                            <ProductGalleryItem key={item.id} i={i} item={item} setScroll={() => scrollPosition}/>
                                        ))
                                    }
                                </MDBRow>
                            </InfiniteScroll>
                    }
                </div>
            }
        </div>
    )
});

const MapStateToProps = ({products: {items, limit, offset}, alerts: {isLoadingAlerts}}) => ({
    items,
    limit,
    offset,
    isLoadingAlerts
})

const MapDispatchToProps = dispatch => ({
    getWebinarPopUp: getWebinarPopUp(dispatch),
    getProducts: getProducts(dispatch),
    addMoreProducts: addMoreProducts(dispatch),
    setOffset: offset => dispatch(setOffset(offset)),
    setLoadAlerts: flag => dispatch(setLoadAlerts(flag))    // for waiting load
})
export default connect(MapStateToProps, MapDispatchToProps)(ProductPage);

