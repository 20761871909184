import { PhysicalProductsActionTypes } from './physical-products.types';
import { RestAPI } from '../api-config';
import axios from 'axios';
import { serialize } from '../../utils/appUtils';
import { parseProductsImages } from '../../utils/parseProductimages';

export const getPhysicalProductCategories = dispatch => async (selectedProdType) => {
    try {
        const result = await axios.get(RestAPI.ORIGINAL_ENDPOINT + `products/categories/getCategories?family=${selectedProdType}`);
        return result.data;
    } catch (error) {
        console.log(error.message);
    }
}

export const getPhysicalProductManufacturers = dispatch => async (selectedProdType,selectedCategory) => {
    try {
        if (selectedCategory != undefined){
            const result = await axios.get(RestAPI.ORIGINAL_ENDPOINT + `products/categories/getManufacturers?family=${selectedProdType}&category=${selectedCategory}`);
            return result.data.map(function(object) {return object['category']});
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getPhysicalProducts = dispatch => async (limit = null, offset=null, selectedProdType=null,selectedCategory=null,selectedManufacturer=null) => {
    const sendInfo = {
        limit: limit,
        offset: offset,
        prodType: selectedProdType,
        product_type: 'physical',
        category: selectedCategory,
        manufacturer: selectedManufacturer
    }
    try {
        if (sendInfo.prodType=='matas'){
            const result = await axios.get(RestAPI.ORIGINAL_ENDPOINT + `products/getall?${serialize(sendInfo)}`);
            const data = parseProductsImages(result?.data?.data);
            dispatch(setPhysicalProducts({
                count: result.data.length,
                data
            }));
        } else {
            const result = {count:0,data:[]};
           // const lipseyResult = await axios.get(`https://55um6grbxb.execute-api.us-east-1.amazonaws.com/prod/products/LipseysProducts?${serialize(sendInfo)}`);
            const lipseyResult = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `products/LipseysProducts?${serialize(sendInfo)}`);
            console.log("lipseyResult", lipseyResult.data);
            result.data = parseProductsImages(lipseyResult.data);
            result.count=lipseyResult.data.length;
            dispatch(setPhysicalProducts(result));
        }  
    } catch (error) {
        console.log(error.message);
    }
}

export const addMoreProducts = dispatch => async (limit = null, offset=null, type=null) => {
    const sendInfo = {
        limit: limit,
        offset: offset,
        product_type: type
    }
    try {
        const result = await axios.get(RestAPI.ORIGINAL_ENDPOINT + `products/getall?${serialize(sendInfo)}`);
        const data = parseProductsImages(result.data);
        dispatch(addProducts({
            count: result.data.length,
            data
        }));
    } catch (error) {
        console.log(error.message);
    }
}

export const setLimit = limit => ({
    type: PhysicalProductsActionTypes.SET_LIMIT,
    payload: limit
})

export const setOffset = offset => ({
    type: PhysicalProductsActionTypes.SET_OFFSET,
    payload: offset
})

const setPhysicalProducts = items => ({
    type: PhysicalProductsActionTypes.SET_PRODUCTS,
    payload: items
})

const addProducts = items => ({
    type: PhysicalProductsActionTypes.ADD_MORE_PRODUCTS,
    payload: items
})
